import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { value } from 'lodash/seq';
import BaseView from '../../views/baseView/baseView';
import icExcel from '../../../assets/images/new/excel.svg';
import { GeneralDropdown } from '../../views/newInputs/inputs';
import {
  getSuperMerchants, merchantCreditSummary, orderCountsByMonth, storeCount,
} from '../../../helpers/dataManager';
import { months, monthsLong } from '../../../helpers/constant';
import { priceFormat } from '../../../utils/formatter';

Chart.register(CategoryScale);
function SuperMerchant({ styles }) {
  const [selectedRange, setSelectedRange] = useState(30);
  const [labelData, setLabelData] = useState(null);
  const [selectedFilterDate, setSelectedFilterDate] = useState(null);
  const [filterDateList, setFilterDateList] = useState([]);
  const [storeCountNumber, setStoreCountNumber] = useState(0);
  const [chart1, setChart1] = useState(null);

  const [subMerchantList, setSubMerchantList] = useState([]);
  const [selectedSubMerchant, setSelectedSubMerchant] = useState(null);

  const currentRef = useRef(true);
  useEffect(() => {
    if (currentRef.current) {
      getMerchantBranches();
      getStoreCount();
    }
    currentRef.current = false;
  }, []);
  useEffect(() => {
    // chart
    getCreditSummary();
  }, [selectedSubMerchant]);

  useEffect(() => {
    handleDateChange(selectedRange);
  }, [selectedRange]);
  useEffect(() => {
    if (filterDateList.length > 0) {
      handleDateChange(30);
    }
  }, [filterDateList]);
  function getStoreCount() {
    storeCount()
      .then((res) => {
        setStoreCountNumber(res);
      })
      .catch(() => {});
  }
  function getOrderCountsByMonth(filterDateListData) {
    orderCountsByMonth()
      .then((res) => {
        prepareDropdown(filterDateListData, res);
      })
      .catch((err) => {});
  }

  function getMerchantBranches() {
    getSuperMerchants()
      .then((res) => {
        setSubMerchantList(res.map((item) => ({ ...item, key: item.id, value: item.merchantName })));
      })
      .catch(() => {});
  }
  function prepareDropdown(data, byMonthData) {
    const allList = [];
    const list = data.sort((a, b) => new Date(b.year, b.month, 1) - new Date(a.year, a.month, 1));
    for (const i in list) {
      const a = byMonthData.findIndex((d) => String(d.month) === String(list[i].month) && String(d.year) === String(list[i].year));
      if (a > -1) {
        allList.push({ ...list[i], byMonth: byMonthData[a] });
      }
    }
    setFilterDateList(allList.map((d) => ({ ...d, key: d.month, value: `${monthsLong[d.month - 1]} ${d.year}` })));
  }
  function handleDateChange(range) {
    const thisMonth = new Date().getMonth() + 1;
    const thisYear = new Date().getFullYear();

    switch (range) {
      case 30:
        // eslint-disable-next-line no-case-declarations
        const i = filterDateList.findIndex((d) => d.month === thisMonth && d.year === thisYear);
        if (i > -1) {
          setLabelData(filterDateList[i]);
        } else {
          const d = {
            totalAmount: 0,
            totalBankIncome: 0,
            totalCount: 0,
            totalMerchantIncome: 0,
            byMonth: {
              approvalRatio: '%0',
            },
          };
          setLabelData(d);
        }
        break;
      case 12:
        // eslint-disable-next-line no-case-declarations
        const allYear = filterDateList.filter((e) => e.year === thisYear);
        // eslint-disable-next-line no-case-declarations
        const all = {
          totalAmount: Number((allYear.reduce((accumulator, item) => accumulator + item.totalAmount, 0)).toFixed(2)),
          totalBankIncome: Number((allYear.reduce((accumulator, item) => accumulator + item.totalBankIncome, 0)).toFixed(2)),
          totalCount: allYear.reduce((accumulator, item) => accumulator + item.totalCount, 0),
          totalMerchantIncome: Number((allYear.reduce((accumulator, item) => accumulator + item.totalMerchantIncome, 0)).toFixed(2)),
          byMonth: {
            approvalRatio: `%${(Number(allYear.reduce((accumulator, item) => accumulator + Number(item.byMonth.approvalRatio.split('%')[1]), 0)) / allYear.length).toFixed(2)}`,
          },
        };
        setLabelData(all);
        break;
      case 99:
        // eslint-disable-next-line no-case-declarations
        const allData = filterDateList;

        // eslint-disable-next-line no-case-declarations
        const allD = {
          totalAmount: Number((allData.reduce((accumulator, item) => accumulator + item.totalAmount, 0)).toFixed(2)),
          totalBankIncome: Number((allData.reduce((accumulator, item) => accumulator + item.totalBankIncome, 0)).toFixed(2)),
          totalCount: allData.reduce((accumulator, item) => accumulator + item.totalCount, 0),
          totalMerchantIncome: Number((allData.reduce((accumulator, item) => accumulator + item.totalMerchantIncome, 0)).toFixed(2)),
          byMonth: {
            approvalRatio: `%${(Number(allData.reduce((accumulator, item) => accumulator + Number(item.byMonth.approvalRatio.split('%')[1]), 0)) / allData.length).toFixed(2)}`,
          },
        };
        setLabelData(allD);
        break;
      default:
        setLabelData(range);
        break;
    }
  }

  function getCreditSummary() {
    merchantCreditSummary({ storeId: selectedSubMerchant?.key })
      .then((res) => {
        if (filterDateList.length === 0) {
          getOrderCountsByMonth(res);
        }
        prepareChartData({ data: res });
      })
      .catch((err) => {});
  }
  function prepareChartData({ data }) {
    const thisYear = new Date().getFullYear();
    let lastYearData = data.filter((e) => e.year === thisYear - 1);
    let thisYearData = data.filter((e) => e.year === thisYear);
    lastYearData = lastYearData.sort((a, b) => a.month - b.month);
    thisYearData = thisYearData.sort((a, b) => a.month - b.month);
    const allData = [...lastYearData, ...thisYearData];
    const chartDataCount = allData.map((e) => e.totalCount);
    const chartDataAmount = allData.map((e) => e.totalAmount);
    const chartDataMonth = allData.map((e) => e.month);
    setChart1({ count: chartDataCount, amount: chartDataAmount, month: chartDataMonth });
  }
  return (
    <BaseView>
      <div className={styles.main}>
        <div className={styles.c1}>
          <div className={styles.c11}>
            <div
              className={`${styles.c2} ${selectedRange === 30 ? styles.selected : ''}`}
              onClick={() => {
                setSelectedRange(30);
                setSelectedFilterDate(null);
              }}
            >
              Bu Ay
            </div>
            <div
              className={`${styles.c2} ${selectedRange === 12 ? styles.selected : ''}`}
              onClick={() => {
                setSelectedRange(12);
                setSelectedFilterDate(null);
              }}
            >
              Bu Yıl
            </div>
            <div
              className={`${styles.c2} ${selectedRange === 99 ? styles.selected : ''}`}
              onClick={() => {
                setSelectedRange(99);
                setSelectedFilterDate(null);
              }}
            >
              Tümü
            </div>
            <GeneralDropdown
              formStyle="w-224"
              placeHolder="Tarih seçiniz"
              selected={selectedFilterDate}
              dataList={filterDateList}
              setSelected={(e) => {
                if (e === null) {
                  setSelectedRange(30);
                } else {
                  setSelectedRange(e);
                }
                setSelectedFilterDate(e);
              }}
            />
          </div>
          {/* <div className={styles.c12}>
            <img alt="excel" src={icExcel} />
          </div> */}
        </div>
        <div className={styles.c3}>
          <div className={styles.c31}>
            <div className={styles.t1}>Kredi Kullandırım</div>
            <div className={`${styles.c32} m-t-16`}>
              <div className={styles.t2}>Adeti</div>
              <div className={styles.t3}>{labelData?.totalCount}</div>
            </div>
            <div className={`${styles.c32} m-t-4`}>
              <div className={styles.t2}>Tutar</div>
              <div className={styles.t3}>
                {priceFormat(labelData?.totalAmount.toFixed(2) || 0)}
                {' '}
                TL
              </div>
            </div>
          </div>
          <div className={`${styles.c31} m-l-24`}>
            <div className={styles.t1}>Bayi</div>
            <div className={`${styles.c32} m-t-16`}>
              <div className={styles.t2}>Adet</div>
              <div className={styles.t3}>{storeCountNumber}</div>
            </div>
            <div className={`${styles.c32} m-t-4`}>
              <div className={styles.t2}>Kullanım oranı</div>
              <div className={styles.t3}>{labelData?.byMonth?.approvalRatio}</div>
            </div>
          </div>
        </div>
        {chart1 && (
        <div className={styles.chartBack}>
          <div className="d-flex justify-space-between">
            <div className="d-flex f-column">
              <div
                className={`${styles.t3} m-t-16`}
              >
                {priceFormat(Number((chart1.amount.reduce((accumulator, item) => accumulator + item, 0)).toFixed(2)))}
                {' '}
                TL
              </div>
              <div className={`${styles.t2} m-t-8`}>
                {Number((chart1.count.reduce((accumulator, item) => accumulator + item, 0)).toFixed(2))}
                {' '}
                Adet
              </div>
            </div>
            <GeneralDropdown
              placeHolder="Tüm bayiler"
              selected={selectedSubMerchant}
              formStyle="w-300"
              dataList={subMerchantList}
              setSelected={(e) => setSelectedSubMerchant(e)}
            />
          </div>
          {chart1 && (
          <div className={styles.graphCart2}>
            <Bar
              data={{
                labels: chart1.month.map((e) => months[e - 1]),
                datasets: [{
                  type: 'line',
                  label: 'Adet',
                  data: chart1?.count || [],
                  backgroundColor: '#34A853',
                  borderColor: '#34A853',
                  yAxisID: 'y',
                  pointRadius: 0,
                  borderWidth: 2,
                }, {
                  type: 'bar',
                  label: 'Tutar',
                  data: chart1?.amount || [],
                  backgroundColor: '#FBBD05',
                  borderRadius: 2,
                  barThickness: 32,
                  yAxisID: 'y1',
                }],
              }}
              options={chartOptions}
            />
          </div>
          )}
        </div>
        )}
      </div>
    </BaseView>
  );
}

const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: { display: false },
    },
    y: {
      grid: { display: false },
      position: 'right',
    },
    y1: {
      grid: { display: false },
    },
  },
  responsive: true,
  stacked: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      labels: {
        usePointStyle: true,
        padding: 24,
        color: '#BDBDBD',
      },
      display: true,
      position: 'bottom',
      reverse: true,
    },
  },
};
export default SuperMerchant;
