import {
  ERROR_TOAST_ON,
  INFO_TOAST_ON,
  ERROR_TOAST_OFF,
  INFO_TOAST_OFF,
} from '../types';
import toastState from '../initialStates/toastState';

export default (state = toastState, action) => {
  switch (action.type) {
    case ERROR_TOAST_ON:
      return {
        ...state,
        errorToastStatus: true,
        infoToastStatus: false,
        text: action.text,
      };
    case INFO_TOAST_ON:
      return {
        ...state,
        errorToastStatus: false,
        infoToastStatus: true,
        text: action.text,
      };
    case ERROR_TOAST_OFF:
    case INFO_TOAST_OFF:
      return {
        ...state,
        errorToastStatus: false,
        infoToastStatus: false,
        text: '',
      };
    default:
      return state;
  }
};
