import React from 'react';
import './buttons.css';

function GeneralButton({
  buttonTitle, leftIcon, buttonStyle, buttonClick, iconStyle, isGhost, buttonType, isSubmit,
}) {
  const activeStyle = () => {
    switch (buttonType) {
      case 'secondary':
        return 'buttons-secondaryButton';
      case 'tertiary':
        return 'buttons-tertiaryButton';
      default:
        return 'buttons-primaryButton';
    }
  };
  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      onClick={() => {
        if (!isGhost) {
          buttonClick();
        }
      }}
      className={`${activeStyle()} ${buttonStyle} ${isGhost && 'buttons-ghost'}`}
    >
      {leftIcon
            && (
            <img
              alt="icon"
              src={leftIcon}
              className={`${'buttons-buttonIcon'} ${iconStyle} ${isGhost && 'buttons-iconGhost'}`}
            />
            )}
      {buttonTitle}
    </button>
  );
}

export { GeneralButton };
