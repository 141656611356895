import {
  LOADING_TRUE, LOADING_FALSE, FILE_LOADING_TRUE, FILE_LOADING_FALSE,
} from '../types';
import loadingState from '../initialStates/loadingState';

export default (state = loadingState, action) => {
  switch (action.type) {
    case LOADING_TRUE:
      return {
        ...state,
        loadingStatus: true,
        fileLoading: false,
      };
    case FILE_LOADING_TRUE:
      return {
        ...state,
        loadingStatus: true,
        fileLoading: true,
      };
    case LOADING_FALSE:
    case FILE_LOADING_FALSE:
      return {
        ...state,
        loadingStatus: false,
        fileLoading: false,
      };
    default:
      return state;
  }
};
