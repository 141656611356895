import React from 'react';
import './assets/css/App.css';
import './assets/fonts/fonts.css';
import { ApplicationStore } from './context/store';
import Loading from './components/views/modals/loading';
import { ErrorToast, InfoToast } from './components/views/modals/toasts';
import RouteSwitch from './router/RouteSwitch';

function App() {
  return (
    <div className="app">
      <ApplicationStore>
        <RouteSwitch />
        <BaseModals />
      </ApplicationStore>
    </div>
  );
}
export function BaseModals() {
  return (
    <>
      <Loading />
      <InfoToast />
      <ErrorToast />
    </>
  );
}

export default App;
