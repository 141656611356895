import React, { useContext } from 'react';
import { MainContext } from '../../../context/store';
import './loading.css';

function Loading() {
  const { state } = useContext(MainContext);
  if (state.loadingResponse.fileLoading) {
    return (
      <div className="back d-flex f-column">
        <div className="loader" />
        <div className="loading-text">Lütfen bekleyiniz...</div>
      </div>
    );
  }
  if (state.loadingResponse.loadingStatus) {
    return (
      <div className="back">
        <div className="loader" />
      </div>
    );
  }
  return (
    <>
    </>
  );
}
export default Loading;
