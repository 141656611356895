export const LOADING_TRUE = 'loading_true';
export const LOADING_FALSE = 'loading_false';
export const FILE_LOADING_TRUE = 'file_loading_true';
export const FILE_LOADING_FALSE = 'file_loading_false';
export const LOGIN_TRUE = 'login_true';
export const LOGIN_FALSE = 'login_false';
export const ERROR_MESSAGE_ON = 'error_message_on';
export const ERROR_MESSAGE_OFF = 'error_message_off';
export const INFO_MESSAGE_ON = 'info_message_on';
export const INFO_MESSAGE_OFF = 'info_message_off';
export const ERROR_TOAST_ON = 'error_toast_on';
export const ERROR_TOAST_OFF = 'error_toast_off';
export const INFO_TOAST_ON = 'info_toast_on';
export const INFO_TOAST_OFF = 'info_toast_off';
