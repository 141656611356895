import React, { useEffect, useState } from 'react';
import timer from '../../../assets/images/Clock.png';
import './timeCounter.css';

function TimeCounter({ seconds, timeUp = function () {} }) {
  const [timeLeft, setTimeLeft] = useState(seconds);
  useEffect(() => {
    if (!timeLeft) {
      timeUp();
      return;
    }
    const intervalid = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
      // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalid);
  }, [timeLeft]);
  return (
    <div className="cTimer">
      <img src={timer} alt="icon" />
      <div className="timer">{secondToMinute(timeLeft)}</div>
    </div>
  );
}
function secondToMinute(time) {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  const visibleMinutes = String(minutes).length < 2 ? `0${minutes}` : minutes;
  const visibleSeconds = String(seconds).length < 2 ? `0${seconds}` : seconds;
  return `${visibleMinutes}:${visibleSeconds}`;
}
export default TimeCounter;
