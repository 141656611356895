import React, { useEffect, useState } from 'react';
import { GeneralFormInput } from '../../views/inputs/inputs';
import './style.css';
import { GeneralButton } from '../../views/buttons/buttons';
import { toastStore } from '../../../context/globalStores';
import { showToastError } from '../../../context/actions/toastActions';
import { setUserMerchantCredential, setUserSessionId } from '../../../helpers/sessionManager';
import img1 from '../../../assets/images/login1.jpg';
import img2 from '../../../assets/images/login2.jpg';
import { getMerchant, login } from '../../../helpers/dataManager';
import LoginHeader from '../../views/loginHeader';

function Login() {
  const images = [img1, img2];
  const [selectedImage, setSelectedImage] = useState(img1);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  function userLogin() {
    if (userName === '' || password === '') {
      toastStore.dispatch(showToastError({ text: 'Giriş bilgilerinizi kontrol edin.' }));
    } else {
      login({ username: userName, password })
        .then((userData) => {
          const r = (Math.random() + 1).toString(36).substring(2);
          setUserSessionId({
            sessionId: r,
            userRole: userData.userRole,
            userName: `${userData.name} ${userData.surname}`,
            merchantNameAlias: userData.merchantNameAlias,
            accessToken: userData.accessToken,
            refreshToken: userData.refreshToken,
            guid: userData.userGuid,
          });
          getMerchant({ userGuid: userData.userGuid })
            .then((res) => {
              setUserMerchantCredential({
                merchantId: res?.id,
                merchantLogo: res?.merchantLogoUrl,
              });
              window.location.href = '/dashboard';
            })
            .catch(() => {});
        })
        .catch((err) => {});
    }
  }

  useEffect(() => {
    let count = 0;
    setInterval(() => {
      if (count === images.length - 1) {
        count = 0;
      } else {
        count += 1;
      }
      setSelectedImage(images[count]);
    }, 5000);
  }, []);
  return (
    <div className="mainLogin">
      <LoginHeader />
      <div className="flex-column-small">
        <div
          className="loginCont"
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              userLogin();
            }
          }}
        >
          <div className="d-flex f-column justify-center">
            <div className="t-semi-bold foreground-blue f-s-24">
              Hoş Geldiniz
            </div>
            <div className="m-t-16 c-dark-dark-grey f-s-18 t-semi-bold">
              İhtiyaç duyduğun an bankan seninle!
            </div>
            <GeneralFormInput
              formStyle="m-t-32"
              value={userName}
              onChange={(e) => setUserName(e)}
              title="Kullanıcı Adı"
            />
            <GeneralFormInput
              formStyle="m-t-16"
              value={password}
              onChange={(e) => setPassword(e)}
              title="Şifre"
              inputType="password"
            />
            <GeneralButton
              buttonStyle="login-bttn"
              buttonTitle="GİRİŞ"
              buttonClick={() => userLogin()}
            />
          </div>
        </div>
        <div className="d-flex f1 p-relative">
          <div className="imageText">
            İhtiyaç duyduğun anda
            <div>Bankan seninle!</div>
          </div>
          <img className="login-img" alt="img" src={selectedImage} />
        </div>
      </div>
    </div>
  );
}
export default Login;
