import { LOGIN_TRUE, LOGIN_FALSE } from '../types';
import authState from '../initialStates/authState';

export default (state = authState, action) => {
  switch (action.type) {
    case LOGIN_TRUE:
      return {
        ...state,
        loginStatus: true,
      };
    case LOGIN_FALSE:
      return {
        ...state,
        loginStatus: false,
      };
    default:
      return state;
  }
};
