const loadingStore = {
  isReady: false,
  dispatch: () => {
    // console.error('store is NOT ready');
  },
};
const authStore = {
  isReady: false,
  dispatch: () => {
    // console.error('store is NOT ready');
  },
};
const popUpStore = {
  isReady: false,
  dispatch: () => {
    // console.error('store is NOT ready');
  },
};
const toastStore = {
  isReady: false,
  dispatch: () => {
    // console.error('store is NOT ready');
  },
};

export {
  authStore, loadingStore, popUpStore, toastStore,
};
