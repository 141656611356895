import { value } from 'lodash/seq';

const sessionStorageKeys = {
  sessionId: 'm_s_i',
  merchantId: 'm_m_i',
  merchantLogo: 'm_m_l',
  userName: 'm_u_n',
  merchantNameAlias: 'm_m_n_a',
  userRole: 'm_u_r',
  loginStatus: 'm_l_s',
  selectedMerchant: 's_m',
  env: 'a_m_offline_e',
  base_url: 'a_m_offline_b_u',
  token: 'a_m_offline_t',
  apiVersion: 'a_m_offline_a_v',
  statusInterval: 'a_m_offline_s_i',
  createOrderUrl: 'a_m_offline_c_o_url',
  subMerchantList: 'a_m_offline_s_m_l',
  accessToken: 'a_m_offline_a_t',
  refreshToken: 'a_m_offline_r_t',
  userGuid: 'a_m_offline_u_g',
};

const userRoles = {
  superUser: 'SUPER_USER',
  merchantAdmin: 'MERCHANT_ADMIN',
  merchantUser: 'MERCHANT_USER',
  superMerchant: 'SUPER_MERCHANT',
};
const chartColors = [
  'rgba(255, 99, 132, 0.6)',
  'rgba(255, 159, 64, 0.6)',
  'rgba(54, 162, 235, 0.6)',
  'rgba(255, 205, 86, 0.6)',
  'rgba(75, 192, 192, 0.6)',
  'rgba(153, 102, 255, 0.6)',
  'rgba(201, 203, 207, 0.6)',
];

const months = ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Ekm', 'Kas', 'Ara'];
const bankList = [
  {
    key: '032',
    value: 'Teb Bankası',
  },
  {
    key: '013',
    value: 'Deniz Bank',
  },
  {
    key: '046',
    value: 'Akbank',
  },
  {
    key: '064',
    value: 'Türkiye İş Bankası',
  },
  {
    key: '103',
    value: 'Fiba Bank',
  },
  {
    key: '206',
    value: 'Türkiye Finans Bankası',
  },
  {
    key: '034',
    value: 'Aktif Bank',
  },
];
const monthsLong = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
];
const creditStatus = {
  11: 'Kullandırıldı',
  12: 'Bankaya Yönlendirildi',
  13: 'Ön Onay Sayfasına Yönlendirildi',
  5: 'İade',
  6: 'Parçalı İade',
  7: 'Red',
  10: 'Kullandırıma Hazır',
  9: 'Müşteri Kazanımı',
  57: 'Stok Bekleniyor',
};
const goalsMonths = [
  { key: 'M1', value: 'Ocak' },
  { key: 'M2', value: 'Şubat' },
  { key: 'M3', value: 'Mart' },
  { key: 'M4', value: 'Nisan' },
  { key: 'M5', value: 'Mayıs' },
  { key: 'M6', value: 'Haziran' },
  { key: 'M7', value: 'Temmuz' },
  { key: 'M8', value: 'Ağustos' },
  { key: 'M9', value: 'Eylül' },
  { key: 'M10', value: 'Ekim' },
  { key: 'M11', value: 'Kasım' },
  { key: 'M12', value: 'Aralık' },
];
const creditTypes = [
  {
    key: 'SHOPPING_CREDIT',
    value: 'Alışveriş Kredisi',
  },
  {
    key: 'CONSUMER_CREDIT',
    value: 'İhtiyaç Kredisi',
  },
  {
    key: 'COMMERCIAL_CREDIT',
    value: 'Ticari Kredi',
  },
  {
    key: 'SHOPPING_CREDIT_PRE_APPROVED',
    value: 'Ön Onaylı Alışveriş Kredisi',
  },
];
const creditTypesEnum = {
  SHOPPING_CREDIT: 'Alışveriş Kredisi',
  CONSUMER_CREDIT: 'İhtiyaç Kredisi',
  COMMERCIAL_CREDIT: 'Ticari Kredi',
  SHOPPING_CREDIT_PRE_APPROVED: 'Ön Onaylı Alışveriş Kredisi',
};
const targetMonths = [
  {
    key: '2025-01',
    value: 'Ocak 2025',
  },
  {
    key: '2025-02',
    value: 'Şubat 2025',
  },
  {
    key: '2025-03',
    value: 'Mart 2025',
  },
  {
    key: '2025-04',
    value: 'Nisan 2025',
  },
  {
    key: '2025-05',
    value: 'Mayıs 2025',
  },
  {
    key: '2025-06',
    value: 'Haziran 2025',
  },
  {
    key: '2025-07',
    value: 'Temmuz 2025',
  },
  {
    key: '2025-08',
    value: 'Ağustos 2025',
  },
  {
    key: '2025-09',
    value: 'Eylül 2025',
  },
  {
    key: '2025-10',
    value: 'Ekim 2025',
  },
  {
    key: '2025-11',
    value: 'Kasım 2025',
  },
  {
    key: '2025-12',
    value: 'Aralık 2025',
  },
];
export {
  sessionStorageKeys,
  chartColors,
  months,
  creditStatus,
  userRoles,
  bankList,
  monthsLong,
  creditTypes,
  creditTypesEnum,
  goalsMonths,
  targetMonths,
};
