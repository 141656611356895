import React from 'react';
import success from '../../../assets/images/successImg.svg';
import './success.css';
import BaseView from '../../views/baseView/baseView';

function Success() {
  return (
    <BaseView>
      <div className="title1">Kredi Onaylandı!</div>
      <div className="t-regular m-t-16 c-dark-mid-grey text-center">Müşteriye siparişi teslim edebilirsin.</div>
      <img alt="img" src={success} className="m-t-32 success-img" />
    </BaseView>
  );
}

export default Success;
