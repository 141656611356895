import React, { useEffect, useRef, useState } from 'react';
import BaseView from '../../views/baseView/baseView';
import styles from './merchants.module.css';
import icExcel from '../../../assets/images/new/excel.svg';
import {
  GeneralDatePicker, GeneralInput, GeneralDropdown, GeneralMultipleDropdown,
} from '../../views/newInputs/inputs';
import icSearch from '../../../assets/images/new/search.svg';
import icPlus from '../../../assets/images/new/plus.svg';
import icDetail from '../../../assets/images/new/detail.svg';
import icCancel from '../../../assets/images/new/cancel-b.svg';
import icDownload from '../../../assets/images/new/download.svg';
import icEdit from '../../../assets/images/new/edit.svg';
import icDelete from '../../../assets/images/new/delete.svg';
import icCancel2 from '../../../assets/images/new/cancel2.svg';
import { GeneralModal } from '../../views/modals/generalModal';
import { GeneralButton } from '../../views/buttons/buttons';
import {
  getBanks,
  getParameter,
  merchantWithRelation,
  partnerList,
  merchants as getMerchantList,
  updateMerchant,
  createMerchant, createStore,
} from '../../../helpers/dataManager';
import Pagination from '../../views/pagination/pagination';
import { debounce } from '../../../utils/debounce';

function Merchants() {
  // General
  const [selectedSection, setSelectedSection] = useState(1);
  const [merchantSearch, setMerchantSearch] = useState('');
  const [storeSearch, setStoreSearch] = useState('');
  const [merchantShowModal, setMerchantShowModal] = useState(false);
  const [merchantEditModal, setMerchantEditModal] = useState(false);
  const [storeShowModal, setStoreShowModal] = useState(false);
  const [storeEditModal, setStoreEditModal] = useState(false);
  //-------------------

  // Dropdown Lists
  const [sectorTypeList, setSectorTypeList] = useState([]);
  const [commissionTypeList, setCommissionTypeList] = useState([]);
  const [creditTypeList, setCreditTypeList] = useState([]);
  const [integrationTypeList, setIntegrationTypeList] = useState([]);
  const [banks, setBanks] = useState([]);
  const [partners, setPartners] = useState([]);
  const [mainMerchantList, setMainMerchantList] = useState([]);
  //-------------------

  // Dropdown Data
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  //-------------------
  // Data List
  const [merchants, setMerchants] = useState(null);
  const [stores, setStores] = useState(null);
  const merchantPageSize = 20;
  const [merchantTotalPage, setMerchantTotalPage] = useState(0);
  const storePageSize = 20;
  const [storeTotalPage, setStoreTotalPage] = useState(0);
  const merchantPageNumber = useRef(1);
  const storePageNumber = useRef(1);
  //-------------------

  function renderSections() {
    switch (selectedSection) {
      case 1:
        return (
          <>
            <div className="d-flex align-center m-t-24">
              <GeneralInput
                value={merchantSearch}
                rightIcon={icSearch}
                onChange={(e) => setMerchantSearch(e)}
                formStyle={styles.searchBar}
                placeHolder="Unvan, vergi numarası..."
              />
              <div
                className={styles.btn}
                onClick={() => {
                  setSelectedMerchant(JSON.parse(JSON.stringify(merchantModel)));
                  setMerchantEditModal(true);
                }}
              >
                Üye iş yeri ekle
                <img alt="plus" src={icPlus} className="m-l-8" />
              </div>
            </div>
            <div className="d-flex f-column m-t-24">
              <div className={styles.tableTitle}>
                <div>Unvan</div>
                <div>Vergi numarası</div>
                <div>Yetkili ad soyad</div>
                <div>Komisyon oranı</div>
                <div>Kredi türü</div>
                <div>Entegratör</div>
                <div>Entegrasyon türü</div>
                <div />
              </div>
              <div className={styles.tableContent}>
                {merchants?.map((item, index) => (
                  <div key={index} className={`${styles.tableRow} ${(index % 2 === 1) && styles.tableRow1}`}>
                    <div>{item?.merchant?.merchantNameAlias}</div>
                    <div>{item?.merchant?.vkn}</div>
                    <div>{`${item?.contacts[0]?.name || ''} ${item?.contacts[0]?.surname || ''}`}</div>
                    <div>{item?.merchant?.commissionType?.value === 'Fixed' ? `${item?.merchant?.commissionRate} TL` : `%${item?.merchant?.commissionRate}`}</div>
                    <div>{item?.merchant?.creditUsageType?.value}</div>
                    <div>{getPartnerName(item?.merchant?.partner?.key)}</div>
                    <div>{item?.merchant?.integrationType?.value}</div>
                    <div>
                      <div className="d-flex align-center">
                        <div
                          className="c-pointer"
                          onClick={() => {
                            setSelectedMerchant(JSON.parse(JSON.stringify(item)));
                            setMerchantShowModal(true);
                          }}
                        >
                          <img alt="dot" src={icDetail} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex m-b-100">
              <Pagination
                onPageChange={(e) => {
                  setMerchantSearch('');
                  merchantPageNumber.current = e;
                  getMerchants();
                }}
                totalPage={merchantTotalPage}
                currentPage={merchantPageNumber.current}
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="d-flex align-center m-t-24">
              <GeneralInput
                value={storeSearch}
                rightIcon={icSearch}
                onChange={(e) => setStoreSearch(e)}
                formStyle={styles.searchBar}
                placeHolder="Unvan, vergi numarası..."
              />
              <div
                className={styles.btn}
                onClick={() => {
                  setSelectedStore(JSON.parse(JSON.stringify(merchantModel)));
                  setStoreEditModal(true);
                }}
              >
                Bayi ekle
                <img alt="plus" src={icPlus} className="m-l-8" />
              </div>
            </div>
            <div className="d-flex f-column m-t-24">
              <div className={styles.tableTitle}>
                <div>Bağlı olduğu üye iş yeri</div>
                <div>Bayi adı</div>
                <div>Yetkili ad soyad</div>
                <div>Yetkili telefon</div>
                <div>Yetkili e-mail</div>
                <div />
              </div>
              <div className={styles.tableContent}>
                {stores?.content?.map((item, index) => (
                  <div key={index} className={`${styles.tableRow} ${(index % 2 === 1) && styles.tableRow1}`}>
                    <div>{getMainMerchantName(item?.merchant?.mainMerchantId)}</div>
                    <div>{item?.merchant?.merchantNameAlias}</div>
                    <div>{`${item?.contacts[0]?.name || ''} ${item?.contacts[0]?.surname || ''}`}</div>
                    <div>{item?.contacts[0]?.gsm || ''}</div>
                    <div>{item?.contacts[0]?.email || ''}</div>
                    <div>
                      <div className="d-flex align-center">
                        <div
                          className="c-pointer"
                          onClick={() => {
                            setSelectedStore(JSON.parse(JSON.stringify(item)));
                            setStoreShowModal(true);
                          }}
                        >
                          <img alt="dot" src={icDetail} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              </div>
            </div>
            <div className="d-flex m-b-100">
              <Pagination
                onPageChange={(e) => {
                  setStoreSearch('');
                  storePageNumber.current = e;
                  getStores();
                }}
                totalPage={storeTotalPage}
                currentPage={storePageNumber.current}
              />
            </div>
          </>
        );
      default:
        return <div />;
    }
  }

  function handleMerchantDataChange(key1, key2, value, index) {
    if (key1 === 'contacts' || key1 === 'relations') {
      const a = { ...selectedMerchant };
      if (Array.isArray(a[key1]) && a[key1].length > 0) {
        a[key1][index][key2] = value;
      } else {
        a[key1][index] = { [key2]: value };
      }
      setSelectedMerchant(a);
    } else {
      const a = { ...selectedMerchant };
      a[key1][key2] = value;
      setSelectedMerchant(a);
    }
  }
  function handleStoreDataChange(key1, key2, value, index) {
    if (key1 === 'contacts' || key1 === 'relations') {
      const a = { ...selectedStore };
      if (Array.isArray(a[key1]) && a[key1].length > 0) {
        a[key1][index][key2] = value;
      } else {
        a[key1][index] = { [key2]: value };
      }
      setSelectedStore(a);
    } else {
      const a = { ...selectedStore };
      a[key1][key2] = value;
      setSelectedStore(a);
    }
  }
  const currentRef = useRef(true);
  useEffect(() => {
    if (currentRef.current) {
      getParameters();
    }
    currentRef.current = false;
  }, []);
  debounce(() => {
    getMerchants();
  }, [merchantSearch], 500);
  debounce(() => {
    getStores();
  }, [storeSearch], 500);
  function getMerchants() {
    merchantWithRelation({
      page: merchantPageNumber.current - 1, size: merchantPageSize, isStore: false, query: merchantSearch.length < 3 ? '' : merchantSearch,
    })
      .then((res) => {
        const allData = res.content.map((e) => {
          const integratedBanks = e.relations.filter((e) => e.relationType === 'BANK').map((e) => ({ key: e.relatedEntityId, value: '' }));
          const partners = e.relations.filter((e) => e.relationType === 'PARTNER').map((e) => ({ key: e.relatedEntityId, value: '' }));
          const merchant = { ...e.merchant, integratedBanks, partner: partners[0] || null };
          return { ...e, merchant };
        });
        setMerchantTotalPage(res.totalPages);
        setMerchants(allData);
      })
      .catch(() => {});
  }
  function getStores() {
    merchantWithRelation({
      page: storePageNumber.current - 1, size: storePageSize, isStore: true, query: storeSearch.length < 3 ? '' : storeSearch,
    })
      .then((res) => {
        setStores(res);
        setStoreTotalPage(res.totalPages);
      })
      .catch(() => {});
  }
  function getParameters() {
    getParameter({ parameter: 'SectorType' })
      .then((res) => {
        const a = res.map((e) => ({ parameter: e, key: e.id, value: e.value }));
        setSectorTypeList(a);
      })
      .catch(() => {});
    getParameter({ parameter: 'IntegrationType' })
      .then((res) => {
        const a = res.map((e) => ({ parameter: e, key: e.id, value: e.value }));
        setIntegrationTypeList(a);
      })
      .catch(() => {});
    getParameter({ parameter: 'CommissionType' })
      .then((res) => {
        const a = res.map((e) => ({ parameter: e, key: e.id, value: e.value }));
        setCommissionTypeList(a);
      })
      .catch(() => {});
    getParameter({ parameter: 'CreditUsageType' })
      .then((res) => {
        const a = res.map((e) => ({ parameter: e, key: e.id, value: e.value }));
        setCreditTypeList(a);
      })
      .catch(() => {});
    getBanks()
      .then((res) => {
        const a = res.map((e) => ({ key: e.id, value: e.bankName }));
        setBanks(a);
      })
      .catch(() => {});
    partnerList()
      .then((res) => {
        const a = res.map((e) => ({ key: e.partner.id, value: e.partner.title }));
        setPartners(a);
      })
      .catch(() => {});
    getMerchantList(false)
      .then((res) => {
        const a = res.map((e) => ({ key: e.id, value: e.merchantName }));
        setMainMerchantList(a);
      })
      .catch(() => {});
  }
  function getBankName(id) {
    const a = banks.find((b) => b.key === id);
    return a?.value ?? '';
  }
  function getPartnerName(id) {
    const a = partners.find((b) => b?.key === id);
    return a?.value ?? '';
  }
  function getMainMerchantName(id) {
    const a = mainMerchantList.find((b) => b.key === id);
    return a?.value ?? '';
  }
  function saveMerchant() {
    setMerchantEditModal(false);
    const data = { ...selectedMerchant?.merchant, contacts: selectedMerchant?.contacts };
    data.integratedBanks = data.integratedBanks.map((e) => ({ id: e.key }));
    data.partner = { id: data?.partner?.key };
    if (selectedMerchant?.merchant?.id !== undefined) {
      updateMerchant({ id: selectedMerchant?.merchant?.id, body: data })
        .then((res) => {
          setSelectedMerchant(null);
          getMerchants();
        })
        .catch(() => {});
    } else {
      createMerchant({ body: data })
        .then((res) => {
          setSelectedMerchant(null);
          getMerchants();
        })
        .catch(() => {});
    }
  }
  function saveStore() {
    setStoreEditModal(false);
    const data = { ...selectedStore?.merchant, contacts: selectedStore?.contacts };
    data.isStore = true;
    if (selectedStore?.merchant?.id !== undefined) {
      updateMerchant({ id: selectedStore?.merchant?.id, body: data })
        .then((res) => {
          setSelectedStore(null);
          getStores();
        })
        .catch(() => {});
    } else {
      createStore({ body: data })
        .then((res) => {
          setSelectedStore(null);
          getStores();
        })
        .catch(() => {});
    }
  }

  return (
    <BaseView>
      <GeneralModal
        visible={merchantEditModal}
        toggle={() => {
          setMerchantEditModal(false);
          setSelectedMerchant(null);
        }}
      >
        <div className={styles.modal}>
          <div className="d-flex align-center justify-space-between">
            <div className={styles.title1}>Üye İşyeri Detayları</div>
            <div
              className="c-pointer"
              onClick={() => {
                setMerchantEditModal(false);
                setSelectedMerchant(null);
              }}
            >
              <img alt="close" src={icCancel} />
            </div>
          </div>
          <div className="m-t-8 d-flex">
            <div className="f1 m-r-32">
              <div className="m-t-8">
                <GeneralDropdown
                  isLight
                  search
                  title="Sektör"
                  placeHolder="Sektör"
                  dataList={sectorTypeList}
                  formStyle="z1"
                  selected={{
                    key: selectedMerchant?.merchant?.sectorType?.id,
                    value: selectedMerchant?.merchant?.sectorType?.value,
                  }}
                  setSelected={(e) => handleMerchantDataChange('merchant', 'sectorType', e?.parameter)}
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedMerchant?.merchant?.merchantNameAlias}
                  onChange={(e) => handleMerchantDataChange('merchant', 'merchantNameAlias', e)}
                  placeHolder="Unvan"
                  title="Unvan"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedMerchant?.merchant?.vkn}
                  onChange={(e) => handleMerchantDataChange('merchant', 'vkn', e)}
                  placeHolder="Vergi numarası"
                  title="Vergi numarası"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedMerchant?.contacts[0]?.name || ''}
                  onChange={(e) => handleMerchantDataChange('contacts', 'name', e, 0)}
                  placeHolder="Yetkili ad"
                  title="Yetkili ad"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedMerchant?.contacts[0]?.surname || ''}
                  onChange={(e) => handleMerchantDataChange('contacts', 'surname', e, 0)}
                  placeHolder="Yetkili soyad"
                  title="Yetkili soyad"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedMerchant?.contacts[0]?.gsm || ''}
                  onChange={(e) => handleMerchantDataChange('contacts', 'gsm', e, 0)}
                  placeHolder="Yetkili telefon"
                  title="Yetkili telefon"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedMerchant?.contacts[0]?.email || ''}
                  onChange={(e) => handleMerchantDataChange('contacts', 'email', e, 0)}
                  placeHolder="Yetkili e-mail"
                  title="Yetkili e-mail"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedMerchant?.merchant?.address}
                  onChange={(e) => handleMerchantDataChange('merchant', 'address', e)}
                  placeHolder="Adres"
                  title="Adres"
                />
              </div>
            </div>
            <div className="f1">
              <div className="m-t-8">
                <GeneralDatePicker
                  formStyle="z1"
                  darkText
                  value={selectedMerchant?.merchant?.agreementDate}
                  onChange={(e) => handleMerchantDataChange('merchant', 'agreementDate', e.toISOString())}
                  title="Sözleşme imza tarihi"
                />
              </div>
              <div className="m-t-8">
                <GeneralDropdown
                  isLight
                  title="Entegrasyon Türü"
                  placeHolder="Entegrasyon Türü"
                  dataList={integrationTypeList}
                  formStyle="z2"
                  selected={{
                    key: selectedMerchant?.merchant?.integrationType?.id,
                    value: selectedMerchant?.merchant?.integrationType?.value,
                  }}
                  setSelected={(e) => handleMerchantDataChange('merchant', 'integrationType', e?.parameter)}
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedMerchant?.merchant?.commissionRate}
                  onChange={(e) => handleMerchantDataChange('merchant', 'commissionRate', e)}
                  placeHolder="Komisyon oranı"
                  title="Komisyon oranı"
                />
              </div>
              <div className="m-t-8">
                <GeneralDropdown
                  isLight
                  title="Komisyon Türü"
                  placeHolder="Komisyon Türü"
                  dataList={commissionTypeList}
                  formStyle="z3"
                  selected={{
                    key: selectedMerchant?.merchant?.commissionType?.id,
                    value: selectedMerchant?.merchant?.commissionType?.value,
                  }}
                  setSelected={(e) => handleMerchantDataChange('merchant', 'commissionType', e?.parameter)}
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedMerchant?.merchant?.maintenanceFee}
                  onChange={(e) => handleMerchantDataChange('merchant', 'maintenanceFee', e)}
                  placeHolder="Servis Bakım Bedeli"
                  title="Servis Bakım Bedeli"
                />
              </div>
              <div className="m-t-8">
                <GeneralDropdown
                  isLight
                  title="Kredi Türü"
                  placeHolder="Kredi Türü"
                  dataList={creditTypeList}
                  formStyle="z4"
                  selected={{
                    key: selectedMerchant?.merchant?.creditUsageType?.id,
                    value: selectedMerchant?.merchant?.creditUsageType?.value,
                  }}
                  setSelected={(e) => handleMerchantDataChange('merchant', 'creditUsageType', e?.parameter)}

                />
              </div>
              <div className="m-t-8">
                <GeneralMultipleDropdown
                  isLight
                  formStyle="z5"
                  title="Entegre olunan finansal kurumlar"
                  placeHolder="Entegre olunan finansal kurumlar"
                  dataList={banks}
                  selected={selectedMerchant?.merchant?.integratedBanks}
                  setSelected={(e) => handleMerchantDataChange('merchant', 'integratedBanks', e.map((e) => e))}
                />
              </div>
              <div className="m-t-8">
                <GeneralDropdown
                  isLight
                  title="Entegratör"
                  formStyle="z6"
                  placeHolder="Entegratör"
                  dataList={partners}
                  selected={{
                    key: selectedMerchant?.merchant?.partner?.key,
                    value: getPartnerName(selectedMerchant?.merchant?.partner?.key),
                  }}
                  setSelected={(e) => handleMerchantDataChange('merchant', 'partner', e)}
                />
              </div>
              {/* <div className="m-t-8">
                <div className={styles.t4}>Dökümanlar</div>
                <GeneralButton buttonStyle="w100" buttonType="tertiary" buttonTitle="Dosya yükle" />
                <div className={styles.ct2}>
                  <div>Sözleşme_12312.pdf</div>
                  <div className="c-pointer">
                    <img alt="delete" src={icCancel2} />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div>
            <GeneralInput
              darkText
              value={selectedMerchant?.merchant?.freeText}
              onChange={(e) => handleMerchantDataChange('merchant', 'freeText', e)}
              placeHolder="Notlar"
              title="Notlar"
            />
          </div>
          <div className="d-flex justify-center m-t-16">
            <GeneralButton buttonTitle="Kaydet" buttonStyle="w50" buttonClick={() => saveMerchant()} />
          </div>
        </div>
      </GeneralModal>
      <GeneralModal
        visible={merchantShowModal}
        toggle={() => {
          setMerchantShowModal(false);
          setSelectedMerchant(null);
        }}
      >
        <div className={styles.modal}>
          <div className="d-flex align-center justify-space-between">
            <div className={styles.title1}>Üye İşyeri Detayları</div>
            <div
              className="c-pointer"
              onClick={() => {
                setMerchantShowModal(false);
                setSelectedMerchant(null);
              }}
            >
              <img alt="close" src={icCancel} />
            </div>
          </div>
          <div className="m-t-8 d-flex">
            <div className="f1">
              <div className="m-t-8">
                <div className={styles.t1}>Sektör</div>
                <div className={styles.t2}>{selectedMerchant?.merchant?.sectorType?.value}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Unvan</div>
                <div className={styles.t2}>{selectedMerchant?.merchant?.merchantNameAlias}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Vergi numarası</div>
                <div className={styles.t2}>{selectedMerchant?.merchant?.vkn}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Yetkili ad soyad</div>
                <div className={styles.t2}>
                  {`${selectedMerchant?.contacts[0]?.name || ''} ${selectedMerchant?.contacts[0]?.surname || ''}`}
                </div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Yetkili telefon</div>
                <div className={styles.t2}>{selectedMerchant?.contacts[0]?.gsm || ''}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Yetkili e-mail</div>
                <div className={styles.t2}>{selectedMerchant?.contacts[0]?.email || ''}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Adres</div>
                <div className={styles.t2}>{selectedMerchant?.merchant?.address}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Notlar</div>
                <div className={styles.t2}>{selectedMerchant?.merchant?.freeText}</div>
              </div>
            </div>
            <div className="f1">
              <div className="m-t-8">
                <div className={styles.t1}>Sözleşme imza tarihi</div>
                <div className={styles.t2}>{selectedMerchant?.merchant?.agreementDate ? selectedMerchant?.merchant?.agreementDate.split('T')[0] : ''}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Entegrasyon türü</div>
                <div className={styles.t2}>{selectedMerchant?.merchant?.integrationType?.value}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Komisyon oranı</div>
                <div className={styles.t2}>{selectedMerchant?.merchant?.commissionType?.value === 'Fixed' ? `${selectedMerchant?.merchant?.commissionRate} TL` : `%${selectedMerchant?.merchant?.commissionRate}`}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Servis bakım bedeli</div>
                <div className={styles.t2}>
                  {selectedMerchant?.merchant?.maintenanceFee}
                  {' '}
                  TL
                </div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Kredi türü</div>
                <div className={styles.t2}>{selectedMerchant?.merchant?.creditUsageType?.value}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Entegre olunan finansal kurumlar</div>
                <div className={styles.t2}>{selectedMerchant?.merchant?.integratedBanks.map((e) => `${getBankName(e.key)} `)}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Entegratör</div>
                <div className={styles.t2}>{getPartnerName(selectedMerchant?.merchant?.partner?.key)}</div>
              </div>
              {/* <div className="m-t-8">
                <div className={styles.t1}>Dökümanlar</div>
                <div className={styles.t2}>3 adet doküman bulunmaktadır.</div>
                <div className={styles.t3}>
                  Dokümanları indir
                  <img alt="download" className="m-l-8" src={icDownload} />
                </div>
              </div> */}
            </div>
          </div>
          <div className="d-flex align-center justify-end m-t-16">
            {/* <div className={`${styles.t3} c-pointer`}>
              Sil
              <img alt="close" src={icDelete} className="m-l-8" />
            </div> */}
            <div
              className={`${styles.t3} c-pointer`}
              onClick={() => {
                setMerchantShowModal(false);
                setMerchantEditModal(true);
              }}
            >
              Bilgileri Düzenle
              <img alt="close" src={icEdit} className="m-l-8" />
            </div>
          </div>
        </div>
      </GeneralModal>
      <GeneralModal
        visible={storeEditModal}
        toggle={() => {
          setStoreEditModal(false);
          setSelectedStore(null);
        }}
      >
        <div className={styles.modal2}>
          <div className="d-flex align-center justify-space-between">
            <div className={styles.title1}>Bayi Detayları</div>
            <div
              className="c-pointer"
              onClick={() => {
                setStoreEditModal(false);
                setSelectedStore(null);
              }}
            >
              <img alt="close" src={icCancel} />
            </div>
          </div>
          <div className="m-t-8 d-flex f-column">
            <div className="m-t-8">
              <GeneralDropdown
                isLight
                title="Bağlı olduğu üye işyeri"
                placeHolder="Bağlı olduğu üye işyeri"
                dataList={mainMerchantList}
                formStyle="z1"
                selected={{
                  key: selectedStore?.merchant?.mainMerchantId,
                  value: getMainMerchantName(selectedStore?.merchant?.mainMerchantId),
                }}
                setSelected={(e) => handleStoreDataChange('merchant', 'mainMerchantId', e.key)}
              />
            </div>
            <div className="m-t-8">
              <GeneralInput
                darkText
                value={selectedStore?.merchant?.merchantNameAlias}
                onChange={(e) => handleStoreDataChange('merchant', 'merchantNameAlias', e)}
                placeHolder="Bayi Adı"
                title="Bayi Adı"
              />
            </div>
            <div className="m-t-8">
              <GeneralDatePicker
                formStyle="z2"
                darkText
                value={selectedStore?.merchant?.agreementDate}
                onChange={(e) => handleStoreDataChange('merchant', 'agreementDate', e.toISOString())}
                title="Sözleşme imza tarihi"
              />
            </div>
            <div className="m-t-8">
              <GeneralInput
                darkText
                value={selectedStore?.contacts[0]?.name || ''}
                onChange={(e) => handleStoreDataChange('contacts', 'name', e, 0)}
                placeHolder="Yetkili ad"
                title="Yetkili ad"
              />
            </div>
            <div className="m-t-8">
              <GeneralInput
                darkText
                value={selectedStore?.contacts[0]?.surname || ''}
                onChange={(e) => handleStoreDataChange('contacts', 'surname', e, 0)}
                placeHolder="Yetkili soyad"
                title="Yetkili soyad"
              />
            </div>
            <div className="m-t-8">
              <GeneralInput
                darkText
                value={selectedStore?.contacts[0]?.gsm || ''}
                onChange={(e) => handleStoreDataChange('contacts', 'gsm', e, 0)}
                placeHolder="Yetkili telefon"
                title="Yetkili telefon"
              />
            </div>
            <div className="m-t-8">
              <GeneralInput
                darkText
                value={selectedStore?.contacts[0]?.email || ''}
                onChange={(e) => handleStoreDataChange('contacts', 'email', e, 0)}
                placeHolder="Yetkili e-mail"
                title="Yetkili e-mail"
              />
            </div>
            <div className="m-t-8">
              <GeneralInput
                darkText
                value={selectedStore?.merchant?.address}
                onChange={(e) => handleStoreDataChange('merchant', 'address', e)}
                placeHolder="Adres"
                title="Adres"
              />
            </div>
            <div className="m-t-8">
              <GeneralInput
                darkText
                value={selectedStore?.merchant?.freeText}
                onChange={(e) => handleStoreDataChange('merchant', 'freeText', e)}
                placeHolder="Notlar"
                title="Notlar"
              />
            </div>
          </div>
          <div className="d-flex justify-center m-t-16">
            <GeneralButton buttonTitle="Kaydet" buttonStyle="w50" buttonClick={() => saveStore()} />
          </div>
        </div>
      </GeneralModal>
      <GeneralModal
        visible={storeShowModal}
        toggle={() => {
          setStoreShowModal(false);
          setSelectedStore(null);
        }}
      >
        <div className={styles.modal2}>
          <div className="d-flex align-center justify-space-between">
            <div className={styles.title1}>Bayi Detayları</div>
            <div
              className="c-pointer"
              onClick={() => {
                setStoreShowModal(false);
                setSelectedStore(null);
              }}
            >
              <img alt="close" src={icCancel} />
            </div>
          </div>
          <div className="d-flex f-column">
            <div className="m-t-8">
              <div className={styles.t1}>Bayi Adı</div>
              <div className={styles.t2}>{selectedStore?.merchant?.merchantNameAlias}</div>
            </div>
            <div className="m-t-8">
              <div className={styles.t1}>Yetkili ad soyad</div>
              <div className={styles.t2}>{`${selectedStore?.contacts[0]?.name || ''} ${selectedStore?.contacts[0]?.surname || ''}`}</div>
            </div>
            <div className="m-t-8">
              <div className={styles.t1}>Yetkili telefon</div>
              <div className={styles.t2}>{selectedStore?.contacts[0]?.gsm || ''}</div>
            </div>
            <div className="m-t-8">
              <div className={styles.t1}>Yetkili e-mail</div>
              <div className={styles.t2}>{selectedStore?.contacts[0]?.email || ''}</div>
            </div>
            <div className="m-t-8">
              <div className={styles.t1}>Adres</div>
              <div className={styles.t2}>{selectedStore?.merchant?.address}</div>
            </div>
            <div className="m-t-8">
              <div className={styles.t1}>Notlar</div>
              <div className={styles.t2}>{selectedStore?.merchant?.freeText}</div>
            </div>
          </div>
          <div className="d-flex align-center justify-end m-t-16">
            {/* <div className={`${styles.t3} c-pointer`}>
              Sil
              <img alt="close" src={icDelete} className="m-l-8"/>
            </div> */}
            <div
              className={`${styles.t3} c-pointer`}
              onClick={() => {
                setStoreShowModal(false);
                setStoreEditModal(true);
              }}
            >
              Bilgileri Düzenle
              <img alt="close" src={icEdit} className="m-l-8" />
            </div>
          </div>
        </div>
      </GeneralModal>
      <div className={styles.main}>
        <div className="d-flex justify-space-between align-center">
          <div className={styles.c1}>
            <div
              className={`${styles.section} ${selectedSection === 1 && styles.selected}`}
              onClick={() => setSelectedSection(1)}
            >
              Üye iş yeri
            </div>
            <div
              className={`${styles.section} ${selectedSection === 2 && styles.selected}`}
              onClick={() => setSelectedSection(2)}
            >
              Bayi
            </div>
          </div>
          {/* <img alt="excel" src={icExcel} className="c-pointer" /> */}
        </div>
        {renderSections()}
      </div>
    </BaseView>
  );
}

export default Merchants;
const merchantModel = {
  merchant: {
    merchantName: '',
    merchantNameAlias: '',
    address: '',
    vkn: '',
    isHostedPage: false,
    isStore: false,
    mainMerchantId: null,
    merchantLogoUrl: '',
    merchantLogoUrlBase64: null,
    maintenanceFee: 0,
    integrationType: null,
    integrationFee: 0,
    sectorType: null,
    commissionType: null,
    commissionRate: 0,
    creditUsageType: null,
    agreementDate: null,
    freeText: '',
    integratedBanks: [],
    partner: null,
  },
  contacts: [
    {
      name: '',
      gsm: '',
      email: '',
      role: '',
      surname: '',
    },
  ],
};
