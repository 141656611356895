import React from 'react';
import styles from './baseView.module.css';
import Navigator from '../navigator/navigator';
import Header from '../header/header';

function BaseView({ children }) {
  return (
    <div className={styles.main}>
      <Header />
      <div className={styles.comp}>
        <Navigator />
        <div className={styles.child}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default BaseView;
