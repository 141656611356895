import { sessionStorageKeys } from './constant';
import { authStore } from '../context/globalStores';
import { loginFail } from '../context/actions/authActions';

function getSessionValue({ key }) {
  return sessionStorage.getItem({ key });
}

function setSessionValue({ key, value }) {
  sessionStorage.setItem(key, value);
}

function removeSessionValue({ key }) {
  sessionStorage.removeItem({ key });
}

function getUserAccessToken() {
  return sessionStorage.getItem(sessionStorageKeys.sessionId);
}

function setUserSessionId({
  sessionId, userName, userRole, guid, accessToken, refreshToken, merchantNameAlias,
}) {
  sessionStorage.setItem(sessionStorageKeys.userGuid, guid);
  sessionStorage.setItem(sessionStorageKeys.accessToken, accessToken);
  sessionStorage.setItem(sessionStorageKeys.refreshToken, refreshToken);
  sessionStorage.setItem(sessionStorageKeys.sessionId, sessionId);
  sessionStorage.setItem(sessionStorageKeys.userName, userName);
  sessionStorage.setItem(sessionStorageKeys.merchantNameAlias, merchantNameAlias);
  sessionStorage.setItem(sessionStorageKeys.userRole, userRole);
  sessionStorage.setItem(sessionStorageKeys.loginStatus, 'true');
}

function setUserMerchantCredential({ merchantId, merchantLogo }) {
  sessionStorage.setItem(sessionStorageKeys.merchantId, merchantId);
  sessionStorage.setItem(sessionStorageKeys.merchantLogo, merchantLogo);
}

function clear() {
  sessionStorage.clear();
  authStore.dispatch(loginFail());
  window.location.href = '/login';
}

function setApplicationSettings({
  env, token, baseUrl, apiVersion, statusInterval, createOrderUrl,
}) {
  sessionStorage.setItem(sessionStorageKeys.env, env);
  sessionStorage.setItem(sessionStorageKeys.token, token);
  sessionStorage.setItem(sessionStorageKeys.base_url, baseUrl);
  sessionStorage.setItem(sessionStorageKeys.apiVersion, apiVersion);
  sessionStorage.setItem(sessionStorageKeys.statusInterval, statusInterval);
  sessionStorage.setItem(sessionStorageKeys.createOrderUrl, createOrderUrl);
}

function getApplicationSettings() {
  const env = sessionStorage.getItem(sessionStorageKeys.env);
  const token = sessionStorage.getItem(sessionStorageKeys.token);
  const baseUrl = sessionStorage.getItem(sessionStorageKeys.base_url);
  const apiVersion = sessionStorage.getItem(sessionStorageKeys.apiVersion);
  const statusInterval = sessionStorage.getItem(sessionStorageKeys.statusInterval);
  const createOrderUrl = sessionStorage.getItem(sessionStorageKeys.createOrderUrl);
  if (env && token && baseUrl && apiVersion && statusInterval && createOrderUrl) {
    return {
      env, token, baseUrl, apiVersion, statusInterval, createOrderUrl,
    };
  }
  return null;
}
export {
  getSessionValue,
  setSessionValue,
  removeSessionValue,
  getUserAccessToken,
  setUserSessionId,
  setApplicationSettings,
  getApplicationSettings,
  clear,
  setUserMerchantCredential,
};
