import React, {
  createContext, useEffect, useMemo, useReducer,
} from 'react';
import authState from './initialStates/authState';
import popUpState from './initialStates/popUpState';
import loadingState from './initialStates/loadingState';
import toastState from './initialStates/toastState';
import authReducer from './reducers/authReducer';
import popUpReducer from './reducers/popUpReducer';
import loadingReducer from './reducers/loadingReducer';
import toastReducer from './reducers/toastReducer';
import {
  loadingStore, popUpStore, authStore, toastStore,
} from './globalStores';
// create context
export const MainContext = createContext();

function ApplicationStore({ children }) {
  const [authResponse, authDispatch] = useReducer(authReducer, authState);
  const [popUpResponse, popUpDispatch] = useReducer(popUpReducer, popUpState);
  const [toastResponse, toastDispatch] = useReducer(toastReducer, toastState);
  const [loadingResponse, loadingDispatch] = useReducer(
    loadingReducer,
    loadingState,
  );
    // Global store imports
  useEffect(() => {
    if (!loadingStore.isReady) {
      loadingStore.isReady = true;
      loadingStore.dispatch = (params) => loadingDispatch(params);
      Object.freeze(loadingStore);
    }
    if (!popUpStore.isReady) {
      popUpStore.isReady = true;
      popUpStore.dispatch = (params) => popUpDispatch(params);
      Object.freeze(popUpStore);
    }
    if (!toastStore.isReady) {
      toastStore.isReady = true;
      toastStore.dispatch = (params) => toastDispatch(params);
      Object.freeze(toastStore);
    }
    if (!authStore.isReady) {
      authStore.isReady = true;
      authStore.dispatch = (params) => authDispatch(params);
      Object.freeze(authStore);
    }
  }, []);
  //------------------------

  const value = useMemo(() => (
    {
      state: {
        authResponse, popUpResponse, loadingResponse, toastResponse,
      },
      dispatch: {
        authDispatch, popUpDispatch, loadingDispatch, toastDispatch,
      },
    }
  ), [authResponse, popUpResponse, loadingResponse, toastResponse]);
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
}

export { ApplicationStore };
