import React, { useEffect, useRef, useState } from 'react';
import arrow from '../../../assets/images/arrow2.png';
import './pagination.css';

function Pagination({ totalPage, currentPage, onPageChange = function () {} }) {
  const [val, setVal] = useState(1);
  useEffect(() => {
    setVal(currentPage);
  }, [currentPage]);
  if (totalPage === 0) {
    return <div />;
  }
  return (
    <div className="pagination-cont">
      <div className="pagination-contBorder">
        {currentPage !== 1 && (
        <div className="pagination-in4" onClick={() => onPageChange(currentPage - 1)}>
          <img alt="next" src={arrow} className="pagination-arrowL" />
          {val - 1}
        </div>
        )}
        <div className="pagination-inp">
          {val}
          /
          {totalPage}
        </div>
        {currentPage !== totalPage && (
        <div className="pagination-in5" onClick={() => onPageChange(currentPage + 1)}>
          {val + 1}
          <img alt="next" src={arrow} className="pagination-arrowR" />
        </div>
        )}
      </div>
    </div>
  );
}

export default Pagination;
