import { getApplicationSettings, setApplicationSettings } from './sessionManager';

async function applicationEnv() {
  return new Promise((resolve, reject) => {
    const settings = getApplicationSettings();
    if (settings) {
      resolve(settings);
    } else {
      const settingsUrl = process.env.REACT_APP_S_U;
      fetch(settingsUrl)
        .then((res) => res.json())
        .then((result) => {
          setApplicationSettings({
            env: result.a_m_offline_e,
            baseUrl: result.a_m_offline_b_u,
            token: result.a_m_offline_t,
            apiVersion: result.a_m_offline_a_v,
            statusInterval: result.a_m_offline_s_i,
            createOrderUrl: result.a_m_offline_c_o_url,
          });
          resolve({
            env: result.a_m_offline_e,
            baseUrl: result.a_m_offline_b_u,
            apiVersion: result.a_m_offline_a_v,
            token: result.a_m_offline_t,
            statusInterval: result.a_m_offline_s_i,
            createOrderUrl: result.a_m_offline_c_o_url,
          });
        })
        .catch(() => {});
    }
  });
}

export default applicationEnv;
