import React, { useContext, useEffect } from 'react';
import { MainContext } from '../../../context/store';
import { ERROR_TOAST_OFF, INFO_TOAST_OFF } from '../../../context/types';
import './toasts.css';

function ErrorToast() {
  const { state, dispatch } = useContext(MainContext);
  const { errorToastStatus, text } = state.toastResponse;
  function toggle() {
    dispatch.toastDispatch({ type: ERROR_TOAST_OFF });
  }
  useEffect(() => {
    if (errorToastStatus) {
      setTimeout(() => {
        toggle();
      }, 3000);
    }
  }, [errorToastStatus]);
  if (errorToastStatus) {
    return (
      <div className="main">
        <div className="snackbar">{text}</div>
      </div>
    );
  }
  return (
    <div />
  );
}

function InfoToast() {
  const { state, dispatch } = useContext(MainContext);
  const { infoToastStatus, text } = state.toastResponse;
  function toggle() {
    dispatch.toastDispatch({ type: INFO_TOAST_OFF });
  }
  useEffect(() => {
    if (infoToastStatus) {
      setTimeout(() => {
        toggle();
      }, 3000);
    }
  }, [infoToastStatus]);
  if (infoToastStatus) {
    return (
      <div className="main">
        <div className={`${'snackbar'} ${'info'}`}>{text}</div>
      </div>
    );
  }
  return (
    <div />
  );
}

export {
  ErrorToast,
  InfoToast,
};
