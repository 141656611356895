import React from 'react';
import './fail.css';
import { useLocation, useNavigate } from 'react-router-dom';
import success from '../../../assets/images/failImg.svg';
import { GeneralButton } from '../../views/buttons/buttons';
import { addMinutes, convertLocalToUTCDateTime } from '../../../utils/formatter';
import { jsonData } from '../../../helpers/dataManager';
import applicationEnv from '../../../helpers/envManager';
import BaseView from '../../views/baseView/baseView';

function Fail() {
  const navigate = useNavigate();
  const { state } = useLocation();
  async function loanApp() {
    const env = await applicationEnv();
    const { createOrderUrl } = env;
    const { body, gsm } = state;
    const expireDate = convertLocalToUTCDateTime(addMinutes(new Date(), -160));
    const newOrderNumber = `${state.orderNumber}-${Number(state.reSendCount + 1)}`;
    body.expireDate = expireDate;
    body.orderNumber = newOrderNumber;
    jsonData(body)
      .then((res) => {
        navigate('/result', {
          state: {
            gsm,
            url: `${createOrderUrl}/hostedpayment?code=${res.jsonKey}`,
            orderNumber: body.orderNumber,
            body,
          },
        });
      })
      .catch(() => {
      });
  }
  return (
    <BaseView>
      <div className="title1">Kredi Onaylanmadı!</div>
      <div className="t-regular m-t-16 c-dark-mid-grey text-center">
        Müşteriye başka bankadan yeni kredi talebi
        oluşturabilirsin.
      </div>
      <img alt="img" src={success} className="m-t-32 fail-img" />
      <GeneralButton
        buttonTitle="Linki Tekrar Gönder"
        buttonStyle="m-t-30 w-200"
        buttonClick={() => loanApp()}
      />
    </BaseView>
  );
}

export default Fail;
