import React, {
  useRef,
} from 'react';
import './generalModal.css';

function GeneralModal({
  children, visible, toggle = function () {},
}) {
  const layoutRef = useRef(null);

  if (visible) {
    return (
      <div className="modalBack" onClick={() => { toggle(false); }}>
        <div ref={layoutRef} className="modal">
          <div
            className="modalContent"
            onClick={(event) => { event.stopPropagation(); }}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
  return (<div />);
}

export { GeneralModal };
