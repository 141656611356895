const phoneFormat = (input) => {
  // eslint-disable-next-line no-param-reassign
  input = input.replace(/\D/g, '');
  // eslint-disable-next-line no-param-reassign
  input = input.substring(0, 10);
  if (input[0] !== '5') {
    return '';
  }
  const num = `(${
    input.substring(0, 3)
  }${input.length > 3 ? ') ' : ''
  }${input.substring(3, 6)
  }${input.length > 6 ? ' ' : ''
  }${input.substring(6, 8)
  }${input.length > 8 ? ' ' : ''
  }${input.substring(8, 10)}`;
  return num;
};

function clearPhoneFormat(val) {
  return val.replace(/\D/g, '');
}

function onlyNumberFormat(val) {
  return String(val).replace(/[^\d]/, '');
}
function daysBetween(first, second) {
  // Copy date parts of the timestamps, discarding the time parts.
  const one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
  const two = new Date(second.getFullYear(), second.getMonth(), second.getDate());

  // Do the math.
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const millisBetween = two.getTime() - one.getTime();
  const days = millisBetween / millisecondsPerDay;

  // Round down.
  return Math.floor(days);
}

function tcknCheck(tckn) {
  if (tckn.length !== 11) return false;
  const tcknNumberList = tckn.split('').map((e) => Number(e));
  const conditionOne = ((tcknNumberList[0] + tcknNumberList[1] + tcknNumberList[2] + tcknNumberList[3] + tcknNumberList[4] + tcknNumberList[5] + tcknNumberList[6] + tcknNumberList[7] + tcknNumberList[8] + tcknNumberList[9]) % 10) === tcknNumberList[10];
  if (!conditionOne) return false;
  const conditionTwoData = (((tcknNumberList[0] + tcknNumberList[2] + tcknNumberList[4] + tcknNumberList[6] + tcknNumberList[8]) * 7) + ((tcknNumberList[1] + tcknNumberList[3] + tcknNumberList[5] + tcknNumberList[7]) * 9)) % 10;
  const conditionTwo = conditionTwoData === tcknNumberList[9];
  if (!conditionTwo) return false;
  const conditionThreeData = ((tcknNumberList[0] + tcknNumberList[2] + tcknNumberList[4] + tcknNumberList[6] + tcknNumberList[8]) * 8) % 10;
  const conditionThree = conditionThreeData === tcknNumberList[10];
  return conditionOne && conditionTwo && conditionThree;
}

function convertLocalToUTCDate(d) {
  let date = d;
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  return date;
}
function convertLocalToUTCDateTime(d) {
  let date = d;
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  return date;
}
function addMonths(date, months) {
  date.setMonth(date.getMonth() + months);
  return date;
}

function filterByDateDesc(array) {
  return array.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
}
function priceFormat(v) {
  let valArr = '00';
  try {
    const a = Number(v).toFixed(2);
    valArr = String(a);
  } catch (err) {
    /* console.error(err); */
  }
  if (!valArr.includes('.')) {
    valArr += ',00';
  }
  if (valArr.split('.')[1]?.length === 1) {
    valArr += '0';
  }
  valArr = valArr.replace('.', ',');
  valArr = valArr.split('');
  let res = '';
  const c = valArr.length - 1;
  for (const i in valArr) {
    let t = '';
    if (c - i === 6 || c - i === 9 || c - i === 12) {
      t = '.';
    }
    res += valArr[i] + t;
  }
  return res;
}
function filterById(array) {
  return array.sort((a, b) => b.id - a.id);
}
function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

export {
  phoneFormat,
  clearPhoneFormat,
  onlyNumberFormat,
  daysBetween,
  tcknCheck,
  convertLocalToUTCDate,
  addMonths,
  filterByDateDesc,
  priceFormat,
  addMinutes,
  convertLocalToUTCDateTime,
  filterById,
};
