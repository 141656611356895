import React from 'react';
import {
  Route, Routes, Navigate, BrowserRouter,
} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { getUserAccessToken } from '../helpers/sessionManager';
import Login from '../components/pages/login/login';
import Main from '../components/pages/main/main';
import Fail from '../components/pages/fail/fail';
import Success from '../components/pages/success/success';
import Search from '../components/pages/search/search';
import Result from '../components/pages/result/result';
import ResultSuccess from '../components/pages/resultSuccess/resultSuccess';
import ResultFail from '../components/pages/resultFail/resultFail';
import Analysis from '../components/pages/analysis/analysis';
import StatusCheck from '../components/pages/statusCheck/statusCheck';
import { sessionStorageKeys, userRoles } from '../helpers/constant';
import Dashboard from '../components/pages/dashboard/dashboard';
import Financials from '../components/pages/financials/financials';
import Merchants from '../components/pages/merchants/merchants';
import Reports from '../components/pages/reports/reports';
import Goals from '../components/pages/goals/goals';
import Users from '../components/pages/users/users';
import Profile from '../components/pages/profile/profile';
import Order from '../components/pages/order/order';
import PaymentStatistics from '../components/pages/paymentStatistics/paymentStatistics';

function RouteSwitch() {
  const userRole = sessionStorage.getItem(sessionStorageKeys.userRole);
  const path = window.location.pathname;
  if (path !== '/statuscheck' && path !== '/login' && path !== '/resultSuccess' && path !== '/resultFail' && (getUserAccessToken() == null || getUserAccessToken() === 'undefined')) {
    window.location.href = '/login';
  }
  return (
    <div className="App">
      <ToastContainer pauseOnFocusLoss autoClose={3000} position={toast.POSITION.TOP_RIGHT} />
      <BrowserRouter>
        <Routes>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/paymentStatistics" element={<PaymentStatistics />} />
          <Route exact path="/financials" element={<Financials />} />
          <Route exact path="/merchants" element={<Merchants />} />
          <Route exact path="/reports/all" element={<Reports statusCode={[11, 7, 6, 5, 12]} />} />
          <Route exact path="/reports/used" element={<Reports statusCode={[11]} />} />
          <Route exact path="/reports/canceled" element={<Reports statusCode={[7]} />} />
          <Route exact path="/reports/refund" element={<Reports statusCode={[5, 6]} />} />
          <Route exact path="/reports/redirected" element={<Reports statusCode={[12]} />} />
          {userRole === userRoles.superUser && <Route exact path="/goals" element={<Goals />} />}
          {/* <Route exact path="/users" element={<Users />} /> */}
          {/* <Route exact path="/profile" element={<Profile />} /> */}
          {(userRole === userRoles.merchantAdmin || userRole === userRoles.merchantUser) && <Route exact path="/order" element={<Order />} />}
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/statuscheck" element={<StatusCheck />} />
          <Route exact path="/result" element={<Result />} />
          <Route exact path="/success" element={<Success />} />
          <Route exact path="/fail" element={<Fail />} />
          <Route exact path="/resultSuccess" element={<ResultSuccess />} />
          <Route exact path="/resultFail" element={<ResultFail />} />
          <Route
            path="*"
            element={(
              <Navigate
                replace
                to="/dashboard"
              />
)}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default RouteSwitch;
