import React from 'react';
import success from '../../../assets/images/success1.svg';
import './resultSuccess.css';

function ResultSuccess() {
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('code');
  const params = code?.split('-') ?? [];
  const orderId = code.substring(params[0].length + 1);
  return (
    <div className="success-back-result">
      <img alt="img" src={success} className="m-t-32 success-img-result" />
      <div className="resText1">Tebrikler!</div>
      <div className="resText3">
        Başvurunuz olumlu değerlendirilip kredi kullandırımınız tamamlanmıştır.
      </div>
      <div className="resText2">
        Sipairiş Numarası:
        {' '}
        {orderId}
      </div>
    </div>
  );
}

export default ResultSuccess;
