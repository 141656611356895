import {
  ERROR_MESSAGE_ON,
  ERROR_MESSAGE_OFF,
  INFO_MESSAGE_ON,
  INFO_MESSAGE_OFF,
} from '../types';
import popUpState from '../initialStates/popUpState';

export default (state = popUpState, action) => {
  switch (action.type) {
    case ERROR_MESSAGE_ON:
      return {
        ...state,
        errorModalStatus: true,
        infoModalStatus: false,
        title: action.title,
        text: action.text,
        buttons: action.buttons,
      };
    case INFO_MESSAGE_ON:
      return {
        ...state,
        errorModalStatus: false,
        infoModalStatus: true,
        title: action.title,
        text: action.text,
        buttons: action.buttons,
      };
    case ERROR_MESSAGE_OFF:
    case INFO_MESSAGE_OFF:
      return {
        ...state,
        errorModalStatus: false,
        infoModalStatus: false,
        title: '',
        text: '',
        buttons: [],
      };
    default:
      return state;
  }
};
