import React, {
  Fragment, useContext, useEffect, useRef, useState,
} from 'react';
import './style.css';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as XLSX from 'xlsx/xlsx.mjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Doughnut, Bar } from 'react-chartjs-2';
import _ from 'lodash';
import PageHeader from '../../views/pageHeader';
import {
  chartColors, creditStatus, months, sessionStorageKeys, userRoles,
} from '../../../helpers/constant';
import { refunds, searchAllMerchants } from '../../../helpers/dataManager';
import {
  addMonths, filterById, onlyNumberFormat, priceFormat,
} from '../../../utils/formatter';
import { GeneralButton } from '../../views/buttons/buttons';
import arrow from '../../../assets/images/icons-solid-down-blue.svg';
import Pagination from '../../views/pagination/pagination';
import { getCreditTotalAmount } from '../../../utils/calculator';
import { GeneralDropdown, GeneralFormInput } from '../../views/inputs/inputs';
import { MainContext } from '../../../context/store';
import { ERROR_TOAST_ON } from '../../../context/types';
import icClose from '../../../assets/images/close.svg';
import { GeneralModal } from '../../views/modals/generalModal';
import icInfo from '../../../assets/images/Info.svg';

Chart.register(CategoryScale);
function Analysis() {
  const { dispatch } = useContext(MainContext);
  const [orders, setOrders] = useState([]);
  const [selectedForRefund, setSelectedForRefund] = useState(null);
  const [monthCheck, setMonthCheck] = useState([false, false, true]);
  const [toShowOrders, setToShowOrders] = useState([]);
  const [timePeriod, setTimePeriod] = useState(12);
  const [chart1, setChart1] = useState(null);
  const [chart2, setChart2] = useState(null);
  const [selectedLine, setSelectedLine] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const currentRef = useRef(true);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedChart, setSelectedChart] = useState({ key: 'Tüm Bankalar', value: 0 });
  const [chartData, setChartData] = useState([]);

  const [productPrice, setProductPrice] = useState('');
  const [productPricePenny, setProductPricePenny] = useState('');

  const userRole = sessionStorage.getItem(sessionStorageKeys.userRole);

  useEffect(() => {
    if (currentRef.current) {
      storageListener();
    }
    currentRef.current = false;
  }, []);

  useEffect(() => {
    if (selectedMerchant) {
      getData();
    }
  }, [selectedMerchant]);
  useEffect(() => {
    if (orders.length > 0) {
      reloadChart1();
      reloadListData();
    }
  }, [timePeriod]);

  useEffect(() => {
    if (orders.length > 0) {
      reloadChart1();
      reloadChart2();
      checkMonthAvailability();
    }
  }, [orders]);
  function handleInfo(order) {
    setSelectedForRefund(order);
  }
  function refundOrder() {
    const refundAmount = Number(productPrice + (productPricePenny === '' ? '.00' : `.${productPricePenny}`));
    const order = selectedForRefund;
    if ((order.amount - order.refundAmount - refundAmount).toFixed(2) >= 0) {
      refunds({
        orderNumber: order.orderId,
        amount: refundAmount.toFixed(2),
        bankCode: order.bank.bankCode,
      })
        .then((res) => {
          getData();
        })
        .catch(() => {});
    } else {
      dispatch.toastDispatch({ type: ERROR_TOAST_ON, text: 'İade tutarı toplam tutardan fazla olamaz!' });
    }
    setSelectedForRefund(null);
    setProductPrice('');
    setProductPricePenny('');
  }
  function reloadListData() {
    const orderList = [...orders].filter((order) => new Date(order.createdDate) > addMonths(new Date(), -timePeriod));
    setToShowOrders(orderList);
  }
  function checkMonthAvailability() {
    if (orders.length > 0) {
      const c1 = [...orders].filter((order) => new Date(order.createdDate) > addMonths(new Date(), -3));
      const c2 = [...orders].filter((order) => new Date(order.createdDate) > addMonths(new Date(), -6));
      setMonthCheck([c1.length > 0, c2.length > 0]);
    }
  }
  function handleSelectedLine(index) {
    setSelectedLine(selectedLine === index ? null : index);
  }
  function storageListener() {
    let sm = null;
    if (userRole === userRoles.superUser || userRole === userRoles.superMerchant) {
      sm = JSON.parse(sessionStorage.getItem(sessionStorageKeys.selectedMerchant)) || { key: 'Tümü', value: null };
    } else if (userRole === userRoles.merchantAdmin) {
      sm = { key: 'Merchant', value: sessionStorage.getItem(sessionStorageKeys.merchantId) };
    }
    setSelectedMerchant(sm);
  }
  function reloadChart1() {
    const orderList = [...orders].filter((order) => new Date(order.createdDate) > addMonths(new Date(), -timePeriod));
    const bankList = _.groupBy(orderList, (item) => item.bank.bankCode);
    for (const i in bankList) {
      const sum = bankList[i].reduce((val, object) => val + object.amount, 0).toFixed(2);
      bankList[i] = { bankName: bankList[i][0]?.bank?.bankName, amount: sum };
    }
    setChart1({
      labels: Object.values(bankList).map((bank) => bank.bankName),
      datasets: [
        {
          label: 'Kullandırım miktarı',
          data: Object.values(bankList).map((data) => data.amount),
          backgroundColor: chartColors,
          borderWidth: 2,
          hoverOffset: 20,
        },
      ],
    });
  }
  function reloadChart2() {
    const orderList = [...orders];
    const bankList = _.groupBy(orderList, (item) => item.bank.bankCode);
    const currentMonth = new Date().getMonth();
    for (const i in bankList) {
      const groupByMonth = _.groupBy(bankList[i], ({ createdDate }) => (new Date(createdDate).getMonth() - ((new Date().getFullYear() > new Date(createdDate).getFullYear()) ? 12 : 0)));
      for (const k in groupByMonth) {
        const sum = groupByMonth[k].reduce((val, object) => val + object.amount, 0).toFixed(2);
        groupByMonth[k] = { monthIndex: Number(k), totalAmount: Number(sum).toFixed(2) };
      }
      for (let t = currentMonth - 12; t < currentMonth + 1; t += 1) {
        if (!groupByMonth[t]) {
          groupByMonth[t] = { monthIndex: t, totalAmount: 0 };
        }
      }
      bankList[i] = { bankName: bankList[i][0]?.bank?.bankName, data: groupByMonth };
    }
    const list = Object.values(bankList).map((bank) => ({
      label: bank.bankName,
      data: Object.values(bank.data).sort((a, b) => a.monthIndex - b.monthIndex).map((data) => data.totalAmount),
      backgroundColor: '#4285F4',
      pointBackgroundColor: '#97b1f9',
      fill: true,
      borderWidth: 2,
      tension: 0.5,
    }));
    // All banks by month
    let allData = [];
    for (const i in list) {
      const bankData = list[i].data;
      if (i === '0') {
        allData = bankData.map((e) => Number(e));
      } else {
        for (const d in bankData) {
          allData[d] = Number(allData[d]) + Number(bankData[d]);
        }
      }
    }
    allData = allData.map((e) => e.toFixed(2));
    const allDataSet = {
      label: 'Tüm Bankalar',
      data: allData,
      backgroundColor: '#4285F4',
      pointBackgroundColor: '#97b1f9',
      fill: true,
      borderWidth: 2,
      tension: 0.5,
    };
    list.unshift(allDataSet);
    //---------
    setChartData(list);
    setChart2({
      labels: months.slice(currentMonth, currentMonth + 13),
      datasets: [list[0]],
    });
  }
  function handleChart2Click(item, index) {
    setSelectedChart({ key: item.label, value: index });
    const newData = { ...chart2 };
    newData.datasets = [chartData[index]];
    setChart2(newData);
  }
  function exportExcel() {
    const orderList = toShowOrders.map((e) => ({
      merchantName: e.merchant?.merchantName,
      orderId: e.orderId,
      bank: e.bank.bankName,
      status: creditStatus[e.applicationStatusCode],
      amount: e.amount,
      createdDate: e.createdDate,
    }));
    const ws = XLSX.utils.json_to_sheet(orderList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'credits');
    XLSX.writeFile(wb, 'credits.xlsx');
  }
  function getData() {
    const endDate = new Date();
    endDate.setHours(endDate.getHours() + 3);
    const startDate = addMonths(new Date(endDate), -12);
    if (selectedMerchant && selectedMerchant?.value !== null) {
      searchAllMerchants({
        startDate,
        endDate,
        orderId: null,
        applicationStatusCode: 11,
        merchants: [selectedMerchant?.value],
      })
        .then((res) => {
          setOrders(res);
          setToShowOrders(res);
        })
        .catch(() => {
        });
    } else if (userRole === userRoles.superMerchant) {
      const allSubMerchantList = JSON.parse(sessionStorage.getItem(sessionStorageKeys.subMerchantList));
      searchAllMerchants({
        startDate,
        endDate,
        orderId: null,
        applicationStatusCode: 11,
        merchants: allSubMerchantList.map((e) => e.value),
      })
        .then((res) => {
          setOrders(res);
          setToShowOrders(res);
        })
        .catch(() => {
        });
    } else {
      searchAllMerchants({
        startDate,
        endDate,
        orderId: null,
        applicationStatusCode: 11,
      })
        .then((res) => {
          setOrders(res);
          setToShowOrders(res);
        })
        .catch(() => {
        });
    }
  }
  return (
    <div className="main-back-analysis">
      <GeneralModal visible={selectedForRefund !== null} toggle={() => setSelectedForRefund(null)}>
        <div className="refundCard">
          <div className="d-flex align-center">
            <div className="title1 f1">İade Edilecek Tutarı Girin</div>
            <div onClick={() => setSelectedForRefund(null)} className="p-8 c-pointer"><img alt="cancel" src={icClose} /></div>
          </div>
          <GeneralFormInput
            price
            pennyValue={productPricePenny}
            pennyOnChange={(e) => setProductPricePenny(onlyNumberFormat(e))}
            value={productPrice}
            onChange={(e) => setProductPrice(onlyNumberFormat(e))}
            formStyle="m-t-20"
            placeHolder="00"
          />
          <div className="d-flex f-column align-center">
            <GeneralButton isGhost={productPrice === ''} buttonTitle="İade Et" buttonStyle="m-t-20 w100" buttonClick={() => refundOrder()} />
            <GeneralButton buttonTitle="Vazgeç" buttonType="tertiary" buttonStyle="m-t-20 w100" buttonClick={() => setSelectedForRefund(null)} />
          </div>
        </div>
      </GeneralModal>
      <PageHeader />
      <div className="mainCont">
        {chart1 && chart2 && (
          <div className="chart-cont">
            <div className="chart-cont1">
              <div className="graph-cart">
                <Doughnut
                  data={chart1}
                  options={{
                    responsive: true,
                    plugins: {
                      title: {
                        display: true,
                        text: `Son ${timePeriod} Aylık`,
                      },
                    },
                  }}
                />
                <div className="d-flex m-t-16 align-center">
                  {monthCheck[0] && (
                  <div
                    onClick={() => setTimePeriod(3)}
                    className={`period-title m-r-10 ${timePeriod === 3 && 'selected'}`}
                  >
                    3 Aylık
                  </div>
                  )}
                  {monthCheck[1] && (
                  <div
                    onClick={() => setTimePeriod(6)}
                    className={`period-title m-r-10 ${timePeriod === 6 && 'selected'}`}
                  >
                    6 Aylık
                  </div>
                  )}
                  <div onClick={() => setTimePeriod(12)} className={`period-title ${timePeriod === 12 && 'selected'}`}>
                    12
                    Aylık
                  </div>
                </div>
              </div>
              <div className="graph-cart">
                <div className="d-flex d-flex-small w100 f-column m-b-10">
                  <GeneralDropdown
                    inputStyle="h-38"
                    list={chartData.map((e, i) => ({ key: e.label, value: i, data: e }))}
                    selected={selectedChart}
                    contentStyle="w100"
                    handleSelect={(e) => handleChart2Click(e.data, e.value)}
                  />
                </div>
                <div className="d-flex f1 w100">
                  <Bar
                    data={chart2}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          grid: {
                            display: false,
                          },
                          border: {
                            display: false,
                          },
                        },
                        y: {
                          grid: {
                            display: false,
                          },
                          border: {
                            display: false,
                          },
                        },
                      },
                      responsive: true,
                      plugins: {
                        title: {
                          display: true,
                          text: 'Son 1 Yıllık Kullanım',
                        },
                      },
                    }}
                  />
                </div>
                <div className="chartSelections d-none-small m-t-10">
                  {chartData.map((e, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        handleChart2Click(e, i);
                      }}
                      className={`firm-title ${selectedChart.value === i && 'selected'}`}
                    >
                      {e.label}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        {
            orders.length === 0 && (
            <div className="no-entry">
              Kayıt Bulunamadı
            </div>
            )
        }
        {orders.length > 0 && (
        <div className="d-flex f-column m-t-24 p-r-24 p-l-24 p-b-30 d-flex-small">
          <div className="total-balance">
            <div className="balance-row1">
              Toplam Kullandırılan Kredi
            </div>
            <div className="balance-row2">
              <div>
                {getCreditTotalAmount(orders, 11).count}
                {' '}
                Adet
              </div>
              <span>
                {priceFormat(getCreditTotalAmount(orders, 11).amount.toFixed(2))}
                {' '}
                TL
              </span>
            </div>
          </div>
          <GeneralButton
            buttonTitle="EXCEL İNDİR"
            buttonClick={() => exportExcel()}
          />
        </div>
        )}
        <div className="m-t-100 p-r-24 p-l-24 d-none-small">
          {orders.length > 0 && (
          <div>
            <div className="d-flex justify-space-between align-center m-b-30">
              <div className="total-balance">
                <div className="balance-row1">
                  Toplam Kullandırılan Kredi
                </div>
                <div className="balance-row2">
                  <div>
                    {getCreditTotalAmount(orders, 11).count}
                    {' '}
                    Adet
                  </div>
                  <span>
                    {priceFormat(getCreditTotalAmount(orders, 11).amount.toFixed(2))}
                    {' '}
                    TL
                  </span>
                </div>
              </div>
              <GeneralButton
                buttonStyle="w-300"
                buttonTitle="EXCEL İNDİR"
                buttonClick={() => exportExcel()}
              />
            </div>
            <table className="userTable m-t-48">
              <tbody>
                <tr>
                  <th className="w-150">Üye İş Yeri Adı</th>
                  <th className="w-100">Sipariş Tutarı</th>
                  <th className="w-100">İade Tutarı</th>
                  <th className="w-150">Banka</th>
                  <th className="w-150">Kredi Statusü</th>
                  <th className="w-100">Tarih</th>
                  <th>Sipariş Numarası</th>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th className="w-100" />
                </tr>
                {filterById(toShowOrders).slice((currentPage - 1) * 20, currentPage * 20).map((item, index) => (
                  <Fragment key={index}>
                    <tr className="table-line">
                      <td className={selectedLine === index ? 'openRow w-150' : 'w-150'}>{item.merchant?.merchantName}</td>
                      <td className={selectedLine === index ? 'openRow w-100' : 'w-100'}>
                        {priceFormat(item.amount)}
                        {' '}
                        TL
                      </td>
                      <td className={selectedLine === index ? 'openRow w-100' : 'w-100'}>
                        {priceFormat(item.refundAmount)}
                        {' '}
                        TL
                      </td>
                      <td className={selectedLine === index ? 'openRow w-150' : 'w-150'}>{item.bank.bankName}</td>
                      <td className={selectedLine === index ? 'openRow w-150' : 'w-150'}>
                        <div className={`credit-status-${item.applicationStatusCode}`}>
                          {creditStatus[item.applicationStatusCode]}
                        </div>
                      </td>
                      <td className={selectedLine === index ? 'openRow w-100' : 'w-100'}>{item.createdDate}</td>
                      <td className={selectedLine === index ? 'openRow' : ''}>{item.orderId}</td>
                      <td className={selectedLine === index ? 'openRow' : ''}>
                        <div
                          onClick={() => handleSelectedLine(index)}
                          className={selectedLine === index ? 'table-arrow d-flex align-center w-100' : 'table-arrow d-flex align-center w-100'}
                        >
                          Detayı
                          {' '}
                          {selectedLine === index ? 'Gizle' : 'Gör'}
                          <img src={arrow} alt="arrow" className={selectedLine === index ? 'openArrow' : ''} />
                        </div>
                      </td>
                    </tr>
                    <>
                      {selectedLine === index && (
                      <tr className="table-order">
                        <th className="w-150">
                          <div className="p-l-16">Ürün Adı</div>
                        </th>
                        <th className="w-100">Tutar</th>
                        <th className="w-100">Statü</th>
                      </tr>
                      )}
                      {selectedLine === index && item.orderDetails.map((order, i) => (
                        <tr key={i} className="table-order">
                          <td className="w-150">
                            <div className="p-l-16">
                              {order.itemName}
                            </div>
                          </td>
                          <td className="w-100">
                            {priceFormat(order.itemPrice)}
                            {' '}
                            TL
                          </td>
                          <td className="w-100">{order.orderStatus}</td>
                        </tr>
                      ))}
                      {selectedLine === index && (
                      <div className="h-38" />
                      )}
                    </>
                  </Fragment>
                ))}
              </tbody>
            </table>
            <Pagination
              onPageChange={(e) => setCurrentPage(e)}
              totalPage={Math.floor(toShowOrders.length / 20) + (toShowOrders.length % 20 > 0 ? 1 : 0)}
              currentPage={currentPage}
            />
          </div>
          )}
        </div>
        {orders.length > 0 && (
        <div className="d-flex-small d-flex f-column p-r-24 p-l-24">
          {filterById(toShowOrders).slice((currentPage - 1) * 20, currentPage * 20).map((item, index) => (
            <div key={index} className="order-card">
              <div className="d-flex">
                <div className="d-flex f-column f1">
                  <div className="title1">Sipariş Numarası</div>
                  <div className="text1">{item.orderId}</div>
                </div>
                <div className="d-flex f-column f1">
                  <div className="title1">Üye İş Yeri Adı</div>
                  <div className="text1">
                    <div className="text1">{item.merchant?.merchantName}</div>
                  </div>
                </div>
              </div>
              <div className="d-flex m-t-16">
                <div className="d-flex f-column f1">
                  <div className="title1">Banka</div>
                  <div className="text1">{item.bank.bankName}</div>
                </div>
                <div className="d-flex f-column f1">
                  <div className="title1">Kredi Statüsü</div>
                  <div className="text1">
                    <div className={`credit-status-${item.applicationStatusCode}`}>
                      {creditStatus[item.applicationStatusCode]}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex m-t-16">
                <div className="d-flex f-column f1">
                  <div className="title1">Tutar</div>
                  <div className="text1">
                    {priceFormat(item.amount)}
                    {' '}
                    TL
                  </div>
                </div>
                <div className="d-flex f-column f1">
                  <div className="title1">Tarih</div>
                  <div className="text1">{item.createdDate}</div>
                </div>
              </div>
              <div className="d-flex m-t-16">
                <div className="d-flex f-column f1">
                  <div className="title1">İade Tutarı</div>
                  <div className="text1">
                    {priceFormat(item.refundAmount)}
                    {' '}
                    TL
                  </div>
                </div>
                <div className="d-flex f-column f1" />
              </div>
              {selectedLine === index && item.orderDetails.map((order, i) => (
                <div key={i} className="order-item-card">
                  <div className="title1">
                    Ürün Adı
                  </div>
                  <div className="text1 m-t-4">
                    {order.itemName}
                  </div>
                  <div className="d-flex m-t-16">
                    <div className="d-flex f-column f1">
                      <div className="title1">Tutar</div>
                      <div className="text1">
                        {priceFormat(order.itemPrice)}
                        {' '}
                        TL
                      </div>
                    </div>
                    <div className="d-flex f-column f1">
                      <div className="title1">Statü</div>
                      <div className="text1">{order.orderStatus}</div>
                    </div>
                  </div>
                </div>
              ))}
              <div
                className="text1 d-flex align-center justify-center m-t-16 p-8"
                onClick={() => handleSelectedLine(index)}
              >
                Detayı
                {' '}
                {selectedLine === index ? 'Gizle' : 'Gör'}
                <img src={arrow} alt="arrow" className={selectedLine === index ? 'openArrow' : ''} />
              </div>
            </div>
          ))}
          <Pagination
            onPageChange={(e) => setCurrentPage(e)}
            totalPage={Math.floor(toShowOrders.length / 20) + (toShowOrders.length % 20 > 0 ? 1 : 0)}
            currentPage={currentPage}
          />
        </div>
        )}
      </div>
    </div>
  );
}

export default Analysis;
