import React from 'react';
import styles from './reports.module.css';
import { sessionStorageKeys, userRoles } from '../../../helpers/constant';
import SuperUser from './SuperUser';
import MerchantUser from './MerchantUser';
import SuperMerchant from './SuperMerchant';

function Reports({ statusCode }) {
  const userRole = sessionStorage.getItem(sessionStorageKeys.userRole);
  if (userRole === userRoles.superUser) {
    return <SuperUser statusCode={statusCode} styles={styles} />;
  }
  if (userRole === userRoles.superMerchant) {
    return <SuperMerchant statusCode={statusCode} styles={styles} />;
  }
  if (userRole === userRoles.merchantUser || userRole === userRoles.merchantAdmin) {
    return <MerchantUser statusCode={statusCode} styles={styles} />;
  }
  return <div />;
}

export default Reports;
