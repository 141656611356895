import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import DatePicker from 'react-datepicker';
import icDate from '../../../assets/images/Date.svg';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-datepicker/dist/react-datepicker.css';
import arrow from '../../../assets/images/icons-solid-down-blue.svg';
import eye from '../../../assets/images/icons-solid-eye-on.svg';
import eye1 from '../../../assets/images/icons-solid-eye-off.svg';
import icErr from '../../../assets/images/icons-solid-info.svg';
import './inputs.css';
import icInfo from '../../../assets/images/icons8-info.svg';
import Tooltip from '../tooltip/tooltip';

const status = {
  none: 0,
  focus: 1,
  error: 2,
};

function GeneralFormInput({
  title,
  placeHolder,
  titleStyle,
  inputStyle,
  formStyle,
  errorText,
  inputType = 'text',
  value,
  onChange,
  maxLength,
  onBlur = function () {},
  price,
  pennyValue,
  pennyOnChange,
  info,
  textArea,
}) {
  const [secureOn, setSecureOn] = useState(false);
  const [inputStatu, setInputStatu] = useState(0);
  const inputRef = useRef(null);
  const inputRefPenny = useRef(null);
  useEffect(() => {
    setSecureOn(inputType === 'password');
  }, [inputType]);
  useEffect(() => {
    if (errorText && (errorText !== '')) {
      setInputStatu(status.error);
    } else {
      setInputStatu(status.none);
    }
  }, [errorText]);
  const getStatuClassForm = useCallback(() => {
    switch (inputStatu) {
      case status.none:
        return '';
      case status.error:
        return 'input-contentError';
      case status.focus:
        return 'input-contentFocus';
      default:
        return '';
    }
  }, [inputStatu]);
  return (
    <div className={`${formStyle}`}>
      <div className="d-flex align-center">
        {title && <div className={`${'input-title'} ${titleStyle}`}>{title}</div>}
        {info && (
        <Tooltip text={info} tooltipStyle="input-info">
          <img alt="info" src={icInfo} className="input-info-icon" />
        </Tooltip>
        )}
      </div>
      <div className={`${'input-content'} ${textArea && 'textArea-content'} ${getStatuClassForm()}`}>
        {textArea ? (
          <textarea
            onBlurCapture={() => onBlur()}
            ref={inputRef}
            onFocus={() => setInputStatu(status.focus)}
            onBlur={() => setInputStatu(status.none)}
            className="input-generalInput input-textArea"
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeHolder || ''}
            value={value}
            type={secureOn ? 'password' : 'text'}
            maxLength={maxLength}
          />
        )
          : (
            <input
              onBlurCapture={() => onBlur()}
              ref={inputRef}
              onFocus={() => setInputStatu(status.focus)}
              onBlur={() => setInputStatu(status.none)}
              className={`${'input-generalInput'} ${price ? 'w50' : 'w100'} ${inputStyle} ${price && 'text-right'}`}
              onChange={(e) => onChange(e.target.value)}
              placeholder={placeHolder || ''}
              value={value}
              type={secureOn ? 'password' : 'text'}
              maxLength={maxLength}
            />
          )}
        {price
            && (
            <div>,</div>
            )}
        {price
            && (
            <input
              ref={inputRefPenny}
              className={`${'input-generalInput w50'} ${inputStyle}`}
              onChange={(e) => pennyOnChange(e.target.value)}
              placeholder="00"
              value={pennyValue}
              maxLength={2}
            />
            )}
        {inputType === 'password' && (
        <div className="input-eye" onClick={() => setSecureOn(!secureOn)}>
          <img src={secureOn ? eye1 : eye} alt="icon" />
        </div>
        )}
      </div>
      {errorText
          && (
          <div className="input-errorTextCont">
            <img alt="error" src={icErr} />
            <div className="input-errorText">{errorText}</div>
          </div>
          )}
    </div>
  );
}

function GeneralDropdown({
  contentStyle, title, selected, list = [], info, inputStyle,
  handleSelect = function () {}, isPassive,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const currentRef = useRef(true);
  useEffect(() => {
    if (currentRef.current) {
      window.addEventListener('click', (evt) => {
        setIsOpen(false);
      }, false);
    }
    currentRef.current = false;
  }, []);
  function handleClick(event) {
    setTimeout(() => {
      setIsOpen(!isOpen);
    }, 50);
  }
  return (
    <div className={`${contentStyle} ${'input-drC'}`}>
      <div className="d-flex align-center">
        {title && <div className="input-title">{title}</div>}
        {info && (
        <Tooltip text={info} tooltipStyle="input-info">
          <img alt="info" src={icInfo} className="input-info-icon" />
        </Tooltip>
        )}
      </div>
      <div
        className={`input-content ${inputStyle} ${isPassive && 'input-passive'}`}
        onClick={(event) => {
          if (!isPassive) {
            handleClick(event);
          }
        }}
      >
        <div className="one-row">{selected?.key || 'Seçiniz'}</div>
        {!isPassive && <img src={arrow} alt="arrow" />}
      </div>
      {isOpen && (
      <div className="input-dropdown">
        {list.map((e, i) => <div className="one-row" onClick={() => handleSelect(e)} key={i}>{e.key}</div>)}
      </div>
      )}
    </div>
  );
}

function GeneralDatePicker({
  formStyle, date, setDate = function () {}, title, titleStyle, errorText, startDate, endDate,
}) {
  useEffect(() => {
    if (errorText && (errorText !== '')) {
      setInputStatu(status.error);
    } else {
      setInputStatu(status.none);
    }
  }, [errorText]);
  const [inputStatu, setInputStatu] = useState(0);
  const getStatuClassForm = useCallback(() => {
    switch (inputStatu) {
      case status.none:
        return '';
      case status.error:
        return 'input-contentError';
      case status.focus:
        return 'input-contentFocus';
      default:
        return '';
    }
  }, [inputStatu]);
  const days = ['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pz'];
  const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => 'dd/mm/yyyy',
    },
  };

  return (
    <div className={`input-datePickerMain ${formStyle}`}>
      {title && <div className={`${'input-title'} ${titleStyle}`}>{title}</div>}
      <div className={`${'input-content c-auto'} ${getStatuClassForm()}`}>
        <div className="d-flex align-center justify-space-between w100">
          <DatePicker
            minDate={startDate}
            maxDate={endDate}
            selected={date}
            onChange={(e) => setDate(e)}
            onCalendarOpen={() => setInputStatu(status.focus)}
            onCalendarClose={() => setInputStatu(status.none)}
            showMonthDropdown
            locale={locale}
            showYearDropdown
            dateFormat="dd/MM/yy"
            dropdownMode="select"
            className="input-datePicker"
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
          <img alt="date" src={icDate} />
        </div>
        {errorText && <div className="input-errorText">{errorText}</div>}
      </div>
    </div>
  );
}
export {
  GeneralFormInput,
  GeneralDropdown,
  GeneralDatePicker,
};
