import {
  ERROR_TOAST_ON,
  INFO_TOAST_ON,
} from '../types';

export const showToastError = ({
  text = 'Bir hata oluştu.',
}) => ({
  type: ERROR_TOAST_ON,
  text,
});

export const showToastInfo = ({
  text = 'İşlem başarılı.',
}) => ({
  type: INFO_TOAST_ON,
  text,
});
