import React from 'react';
import './tooltip.css';

function Tooltip({
  children, text, tooltipStyle, tooltipTextStyle,
}) {
  return (
    <div className={`${'tooltip'} ${tooltipStyle}`}>
      {children}
      <span className={`${'tooltiptext'} ${tooltipTextStyle}`}>{text}</span>
    </div>
  );
}

export default Tooltip;
