import React from 'react';
import styles from './dashboard.module.css';
import { sessionStorageKeys, userRoles } from '../../../helpers/constant';
import SuperUser from './SuperUser';
import SuperMerchant from './SuperMerchant';
import MerchantUser from './MerchantUser';

function Dashboard() {
  const userRole = sessionStorage.getItem(sessionStorageKeys.userRole);
  if (userRole === userRoles.superUser) {
    return <SuperUser styles={styles} />;
  }
  if (userRole === userRoles.superMerchant) {
    return <SuperMerchant styles={styles} />;
  }
  if (userRole === userRoles.merchantUser || userRole === userRoles.merchantAdmin) {
    return <MerchantUser styles={styles} />;
  }
  return <div />;
}
export default Dashboard;
